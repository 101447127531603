
var Account = {
    state: {
        accounts: []
    },
    getters: {
        getAccounts: (state) => {
            if (!state.accounts.length) {
                // console.log('No accounts loaded', state);
            }
            return state.accounts;
        },
        getAccountById: (state) => (id) => {
            if (id) {
                return state.accounts.filter(account => account.id == id)[0];
            }
        }
    },
    mutations: {
        setAccounts(state, data) {
            // console.log(state, data);
            state.accounts = data;
        }
    },
    actions: {
        fetchAccounts(context, arg) {
            var data = arg;

            axios.get('/api/accounts')
                .then(response => {
                    var accounts = response.data.data;

                    accounts.forEach(function(account) {
                        account.selected = false;
                    });

                    context.commit('setAccounts', accounts);
                })
                .catch(error => {
                    console.log('Account Get Error');
                })
                .finally();
        },
        fetchAccountsTest(context, accountID) {

        },
        postAccount(context, data) {
            // Probably process `arg` here and maybe
            // slap a user_id onto it?

            // console.log('post Account', String(data.id), data);

            var req = {
                method: (data.id ? 'put' : 'post'),
                tar: (data.id ? '/api/accounts/' + data.id : '/api/accounts')
            }

            // console.log(req);

            // data.user = context.state.user;

            return axios[req.method](req.tar, data);
        }
    }
}

export default Account;
