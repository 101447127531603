<template>
	<div class="account-add-edit">
		<header class="subhead">
			<h2>
				<span v-if="account.id">Edit</span>
				<span v-else>New</span>
				Account
			</h2>

			<!-- <div>
				<button @click="save" class="btn">Save</button>
			</div> -->
		</header>

		<div class="account add-edit">
			<div class="form">
				<div class="input-group" data-validate="name">
					<label>Account Name</label>
					<input type="text" v-model="account.name">
				</div>

				<div class="input-group closer" data-validate="addressOne">
					<label>Address One</label>
					<input type="text" v-model="account.addressOne">
				</div>
				<div class="input-group closer">
					<label>Address Two</label>
					<input type="text" v-model="account.addressTwo">
				</div>
				<div class="input-group closer">
					<label>Address Three</label>
					<input type="text" v-model="account.addressThree">
				</div>
				<div class="input-group closer" data-validate="city">
					<label>City</label>
					<input type="text" v-model="account.city">
				</div>
				<div class="input-group closer" data-validate="state">
					<label>State</label>
					<input type="text" v-model="account.state">
				</div>
				<div class="input-group" data-validate="zip">
					<label>Zip Code</label>
					<input type="text" v-model="account.zip">
				</div>
			</div>

			<div class="actions">
				<button @click="saveAccount" class="btn" :disabled="!changed.account">Save</button>
			</div>
		</div>

		<h2>
			<span v-if="account.id">Edit</span>
			<span v-else>New</span>
			Contacts
		</h2>

		<div class="account-contact add-edit"
			v-for="contact, index in contacts">
			<div class="form">
				<div class="input-group closer" :data-validate="'first_name-' + contact.id">
					<label>Contact First Name</label>
					<input type="text" v-model="contact.first_name">
				</div>
				<div class="input-group" :data-validate="'last_name-' + contact.id">
					<label>Contact Last Name</label>
					<input type="text" v-model="contact.last_name">
				</div>

				<div class="input-group" :data-validate="'email-' + contact.id">
					<label>Contact Email</label>
					<input type="text" v-model="contact.email">
				</div>

				<div class="input-group">
					<label>Contact Title</label>
					<input type="text" v-model="contact.title">
				</div>
			</div>

			<div class="actions">
				<button class="btn" @click="updateContact(contact)" :disabled="!changed.contacts[index]">
					Update
				</button>
			</div>
		</div>

		<div class="new-contact add-edit has-many-add-one" :data-adding="add_mode">
			<div class="form">
				<div class="input-group closer" data-validate="first_name_new">
					<label>Contact First Name</label>
					<input type="text" v-model="new_contact.first_name">
				</div>
				<div class="input-group" data-validate="last_name_new">
					<label>Contact Last Name</label>
					<input type="text" v-model="new_contact.last_name">
				</div>

				<div class="input-group" data-validate="email_new">
					<label>Contact Email</label>
					<input type="text" v-model="new_contact.email">
				</div>

				<div class="input-group">
					<label>Contact Title</label>
					<input type="text" v-model="new_contact.title">
				</div>
			</div>

			<div class="actions">
				<button class="btn"
					:style="add_mode ? '' : 'display: none'"
					@click="saveNewContact">
					Save
				</button>

				<button class="btn"
					:style="add_mode ? 'display: none': ''"
					:disabled="add_mode || !account.id"
					@click="add_mode = !add_mode">
					Add New
				</button>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'accountAddEdit',
	data: function() {
		return {
			account: {},
			contacts: [],
			db_contacts: [],
			new_contact: {
				first_name: '',
				last_name: '',
				email: '',
				title: ''
			},
			add_mode: false,
			changed: {
				account: false,
				contacts: []
			}
		}
	},
	computed: {
		new_contact_is_valid: function() {
			var valid = true;

			// TODO: build helpers.validate function.
			for (var key in this.new_contact) {
				if (this.new_contact[key] == '') {
					valid = false;
				}
			}
			return valid;
		}
	},
	watch: {
		account: {
			deep: true,
			handler: function(val, original) {
				// console.log('account watch', val, original);

				// console.log(val.id);

				var self = this;
				if (!original.id) {
					// Here, original is the empty container
					// in data before mounted completes.
					//
					// TODO: Maybe: This is where we clone
					// val into a new object... if we need
					// to actually track/compare which datum
					// inside the account object changed.
					//
					// this.old.account = this.$clone(val); // probably.

					this.changed.account = true;

					return;
				}

				this.changed.account = true;
			}
		},
		contacts: {
			deep: true,
			handler: function(val, original) {
				if (!original.length) {
					// need to track which contact changed.

					// this.db_contacts = this.$clone(val);

					for (var ind in val) {
						this.db_contacts.push(this.$clone(val[ind]));
					}

					return;
				}

				for (var ind in val) {
					for (var key in val[ind]) {
						if (this.db_contacts[ind] && this.db_contacts[ind][key] != val[ind][key]) {
							this.changed.contacts[ind] = true;
						}
					}
				}
			}
		}
	},
	mounted: function() {
		var self = this;

		// console.log('Account form', this.account);

		// If we have an ID, let's assume we've already fetched the
		// accounts from the DB.
		//
		// Ditto with contacts
		//
		// We basically have to assume this in order to use this watch
		// architecture for change detection. No Async for you.
		if (this.$route.params.id) {
			this.account = this.getAccountById()(self.$route.params.id) || {};
			this.contacts = this.getContactsByAccountId()(self.$route.params.id) || [];

			if (!Object.keys(this.account).length) {
				// this.fetchAccounts();
				this.$emit('notify', 'Something went wrong getting the account.');
			}
		}
	},
	methods: Object.assign(
		mapGetters({
			getAccountById: 'getAccountById',
			getContactsByAccountId: 'getContactsByAccountId'
		}),
		mapActions({
			postAccount: 'postAccount',
			fetchAccounts: 'fetchAccounts',
			fetchContacts: 'fetchContacts',
			postContact: 'postContact'
		}),
		{
			handleUpdate: function(obj) {
				this.$emit('update', obj);
			},
			handleModal: function(arg) {
				this.$emit('modal', arg);
			},
			resetContact: function(contact) {
				this.contacts.push(contact);
				this.db_contacts.push(contact);

				for (var key in this.new_contact) {
					this.new_contact[key] = '';
				}

				this.add_mode = false;
			},
			saveContact: function(contact) {
				var self = this;

				console.log('Saving contact', contact);

				this.postContact(contact)
					.then(response => {

						if (self.add_mode) {
							this.resetContact(response.data.data);
						}

						var name = response.data.data.full_name;

						self.$emit('notify', (name ? name : 'Contact') + ' saved.');
					})
					.catch(error => {
						console.log('Error?', error);
					});
			},
			updateContact: function(contact) {
				var valid = this.$validate('contact', {...contact, suffix: '-' + contact.id});

				if (valid.length) {
					return;
				}

				this.saveContact(contact);
			},
			saveNewContact: function() {
				var data = this.new_contact;
				data.account_id = this.account.id;

				var valid = this.$validate('contact', {...this.new_contact, suffix: '_new'});

				if (valid.length) {
					return;
				}

				this.saveContact(data);
			},
			saveAccount: function() {
				var self = this;

				// validate form.
				var valid = this.$validate('account', this.account);

				if (this.add_mode) {
					var contact_valid = this.$validate('contact', this.new_contact);
					valid.push.apply(valid, contact_valid);
				}

				if (valid.length) {
					return;
				}

				// submit formatted obj to VueX
				var data = this.account;
				data.contacts = [this.new_contact];



				this.postAccount(this.account)
					.then(response => {
						// console.log('Resp raw', response);

						if (!self.account.id) {
							console.log('Setting resp ID', response);
							self.$set(self.account, 'id', response.data.data.id);
						}

						self.changed.account = false;
						// self.changed.contacts = [];

						// if (self.add_mode) {
						// 	this.resetContact(); // get the contact id from DB create
						// }

						self.$emit('notify', (self.account.name ? self.account.name : 'Account') + ' saved.');
					})
					.catch(error => {
						console.log('Error?', error);

						// self.$set(self.account, 'id', 21);

						// if (self.add_mode) {
						// 	this.resetContact();
						// }
					});

				

				// this.$router.push({
				// 	name: 'accounts'
				// });
			}
		}
	)
}
</script>
