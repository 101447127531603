
var History = {
    state: {
        history: []
    },
    getters: {
        getHistory: (state) => {
            return state.history;
        },
        getOrder(state) {
            if (!order_id) {
                return [];
            }
            return state.history;
        }
    },
    mutations: {
        setHistory(state, data) {
            state.history = data;
        },
        pushToHistory(state, data) {
            state.history.push(data);
        }
    },
    actions: {
        fetchHistory(context, arg) {
            var param = '?order_type=order';

            axios.get('/api/orders' + param)
                .then(response => {
                    var orders = response.data.data;

                    orders.forEach(function(order) {
                        order.order_products.forEach(function(item) {
                            item.name = item.part_number;

                            item.image = '/storage/products/' + item.product.image;

                            item.product.name = item.product.part_number;
                            item.product.name += item.product.special ? ' ' + item.product.special : '';
                            item.product.image = '/storage/products/' + item.product.image;
                        });
                    })

                    context.commit('setHistory', orders);
                    // console.log('setHistory', orders);
                })
                .catch(error => {
                    console.log('History Get Error'); // , error
                })
                .finally();
        }
    }
}

export default History;
