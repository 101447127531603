<template>
	<div class="cart-page">
		<div class="subhead">
			<h2>Label Order Cart</h2>

			<div class="input-group">
				<label>Order Name (optional)</label>
				<input type="text" name="order-name" v-model="name">
			</div>
		</div>

		<div class="table-wrapper carts label-cart">
			<table v-if="cart.length">
				<thead>
					<tr>
						<th>Product</th>
						<th>Description</th>
						<th>Quantity</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="product in cart">
						<td>
							<router-link :to="{ path: '/product/' + product.id }">
								{{product.name}}
							</router-link>
						</td>
						<td>{{product.description}}</td>
						<td>
							<input type="number" v-model="product.qty" value="1" :name="'qty-' + product.id">
						</td>
						<td>
							<a v-on:click="remove(product.id)" class="btn">Remove</a>
						</td>
					</tr>
				</tbody>
			</table>

			<div v-else>
				<p>Products added to your Label cart will display here.</p>
			</div>
		</div>

		<div class="cart-actions" v-if="cart.length">
			<a v-on:click="clear()" class="btn">Clear Cart</a>
		</div>

		<div v-if="cart.length">
			<h2>Customer (Shipping Destination)</h2>

			<customerSelectAdd 
				:key="uuid" 
				ref="customerSelectAdd">
			</customerSelectAdd>
		</div>

		<div class="" v-if="cart.length">
			<h3>Submit Order</h3>

			<div class="btn-group">
				<a v-on:click="submit()" class="btn">Submit</a>
			</div>
		</div>
	</div>
</template>

<script>
import customerSelectAdd from "../components/CustomerSelectAdd.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'orders',
	data: function() {
		return {
			name: '',
			uuid: ''
		}
	},
	components: { customerSelectAdd },
	computed: Object.assign(
		mapGetters({}),
		{
			cart() {
				return this.getCart();
			},
			// name: {
			// 	get() {
			// 		return this.getCartData().name;
			// 	},
			// 	set(str) {
			// 		return str;
			// 	}
			// }
		}
	),
	mounted: function() {
		var self = this;

		this.name = this.getCartData().labels_name;
		this.uuid = this.getCartData().labels_uuid;
	},
	watch: {
		cart: {
			deep: true,
			handler: function(val, original) {
				// Handle changes to quantity in cart.
				this.$emit('update', {type: 'cart'});
			}
		},
		name(newName, oldName) {
			this.setCartName(newName);
		}
	},
	methods: Object.assign(
		mapMutations({
			removeFromCart: 'removeFromCart',
			setCartName: 'setCartName',
			setCartUuid: 'setCartUuid',
			pushToHistory: 'pushToHistory'
		}),
		mapGetters({
			getCart: 'getCart',
			getCartData: 'getCartData'
		}),
		mapActions({
			postCart: 'postCart'
		}),
		{
			reset: function(order) {
				this.pushToHistory(order);
				this.clear();

				var new_uuid = window.$uuid();

				this.name = '';

				var message = 'Your Labels';
				message += order.name ? ' "' + order.name + '"'  : '';
				message += ' were generated successfully.';

				// console.log('message', message);

				this.$emit('update', {type: 'cart', message: message});

				// console.log('route', '/order/' + order.id);

				this.$router.push({path: '/order/' + order.id});

				// console.log('Setting uuid...');
				this.setCartUuid(new_uuid);
				this.uuid = new_uuid;
			},
			remove: function(id) {
				this.removeFromCart({id: id});
				this.$emit('update', {type: 'cart'});
			},
			clear: function() {
				var self = this;

				this.cart.forEach(product => {
					self.removeFromCart({id: product.id});
				});
				this.$emit('update', {type: 'cart'});
			},
			submit: function() {
				var self = this,
					account = this.$refs.customerSelectAdd.account,
					contact = this.$refs.customerSelectAdd.contact,
					valid = this.$validate('order', {...account, ...contact});

				if (valid.length) {
					return;
				}

				var form_data = {
					saveModeAccount: this.$refs.customerSelectAdd.saveModeAccount,
					saveModeContact: this.$refs.customerSelectAdd.saveModeContact,
					account: {
						...this.$refs.customerSelectAdd.account,
						contact: this.$refs.customerSelectAdd.contact
					},
					type: 'order',
					name: this.name
				}

				this.postCart(form_data)
					.then(response => {
						console.log('Label Resp', response.data);
						self.reset(response.data);
					})
					.catch(error => {
						console.log('Sheet Post Error', error);
					})
					.finally();
			}
		}
	)
}
</script>
