<template>
	<div class="home">
		<h2>Search</h2>

		<!-- <div class="search">
			<h3>Search Subcomponent</h3>
		</div> -->

		<search :autocompleteDisabled="true">
		</search>

		<h2>
			Product categories
		</h2>

		<div class="categories">
			<category
				v-for="category in categories"
				:key="category.id"
				:category="category">
			</category>
		</div>
	</div>
</template>

<script>
// import Category from "../models/Category.js";
import category from "../components/Category.vue";
import search from "../components/Search.vue";
import {mapGetters} from "vuex";

export default {
	name: 'home',
	components: {
		category,
		search
	},
	computed: Object.assign(
		mapGetters({
			categories: "getCategories",
		}),
		{}
	),
	methods: Object.assign(
		mapGetters({
			getCategories: "getCategories",
		}),
		{}
	),
	data: function() {
		return {
			// categories: []
		}
	},
	mounted: function() {
		var self = this;

		// console.log(this.getCategories());

		// this.categories = Category.get();
	}

}
</script>