<template>
	<div class="sales-sheet-page">
		<div class="subhead">
			<router-link class="btn"
				:to="{name: 'sheet'}">
				
				&laquo; Product Sheet PDF
			</router-link>
		</div>


		<div class="sales-sheet preview">
			<div class="pdf-wrapper">
				<div class="sales-sheet-header">
					<div class="pdf-logo">
						<img src="/images/logo.svg" />
					</div>

					<div class="pdf-header">
						<h2>
							Orthopaedic Instruments product sheet
						</h2>
						<p>
							Created: <span>{{created}}</span>
						</p>
					</div>
				</div>

				<ul class="sales-sheet-body">
					<li v-for="(product, ind) in sheet">
						<div class="product-info">
							<p>
								<strong>Part number:</strong> {{product.name}}
							</p>

							<p v-for="(val, attr) in product.attributes">
								<strong>{{$niceAttr(attr)}}:</strong> {{val}}
							</p>

							<p v-if="product.note.text">
								<strong>Additional information:</strong> {{product.note.text}}
							</p>

							<p v-if="product.description">
								<strong>Note:</strong> {{product.description}}
							</p>
						</div>

						<div class="product-image">
							<img v-if="product.image" :src="product.image">
						</div>
					</li>
				</ul>

				<footer class="sales-sheet-footer">
					<p>
						<strong>Your Stryker OI rep:</strong>
					</p>
					<ul>
						<li>{{user.name}}</li>
						<li>{{user.phone}}</li>
						<li>{{user.email}}</li>
					</ul>
					<p>
						Pg: 1 of 1
					</p>
				</footer>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'Sheet',
	data: function() {
		return {
			id: '',
			user: {
				name: 'John Doe',
				phone: '616 234-5678',
				email: 'john-doe@stryker.com'
			}
		}
	},
	mounted: function() {
		var self = this;
		var user_id = 1;

	},
	computed: Object.assign(
		mapGetters({}),
		{
			created: function() {
				return this.id ? this.id : '(Preview)';
			},
			sheet: function() {
				// console.log('Sheet data', this.getSheet());
				return this.getSheet();
			}
		}
	),
	methods: Object.assign(
		mapGetters({
			getSheet: 'getSheet',
		}),
		{
			
		}
	)
}
</script>