<template>
	<div class="bulk-add">
		<button v-if="selection.length != products.length" v-on:click="select('all')" class="btn">
			Select All
		</button>
		<button v-if="selection.length == products.length" v-on:click="select('none')" class="btn">
			Deselect All
		</button>
		<button :disabled="!selection.length" v-on:click="select('invert')" class="btn">
			Invert Selection
		</button>
		<button v-on:click="cartAdd" :disabled="!selection.length" class="btn">
			Add <span v-if="selection.length">{{selection.length}} Selected Products</span> to Label Cart
		</button>
		<button v-on:click="sheetAdd" :disabled="!selection.length" class="btn">
			Add <span v-if="selection.length">{{selection.length}} Selected Products</span> to Product Sheet
		</button>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'bulk-add',
	props: ['selection', 'products'],
	data: function() {
		return {

		}
	},
	mounted: function() {
		
	},
	methods: Object.assign(
		mapMutations({
			removeFromCart: 'removeFromCart',
			addToCart: 'addToCart',
			addToSheet: 'addToSheet'
		}),
		mapGetters({
			getCart: 'getCart',
		}),
		{
			select: function(str) {
				this.$emit('select', str);
			},
			getItem: function(id, qty) {
				var self = this;
				var item = self.products.filter(item => item.id == id)[0];
				var product = {
					id: id,
					part_number: item.part_number,
					description: item.description,
					name: item.name,
					image: item.image,
					note: {}
				}

				return product;
			},
			cartAdd: function() {
				var self = this;

				var cart = this.getCart();

				this.selection.forEach(function(id) {
					if (cart.filter(item => item.id == id).length) {
						self.$emit('update', {
							type: 'cart', 
							message: cart.filter(item => item.id == id)[0].name + ' Already in Cart.'
						});
						return;
					}

					var data = {
						product: self.getItem(id, 1),
						quantity: 1
					}

					self.addToCart(data);

					self.$emit('update', {type: 'cart', message: data.message});

				});

				// this.$emit('update', {type: 'cart'});
			},
			sheetAdd: function() {
				var self = this;

				console.log('Sheet add', this.selection);

				this.selection.forEach(function(id) {
					if (!self.$root.sheet.filter(product => product.id == id).length) {
						// var data = {
						// 	id: id,
						// 	img: this.image ? this.image : false,
						// 	description: this.product.description,
						// 	attributes: this.attributes,
						// 	name: this.product.name,
						// 	note: {},
						// }

						var data = self.getItem(id);

						console.log('Product to add to PDF:', data);

						self.addToSheet(data);

						self.$emit('update', {type: 'sheet', message: data.message});
					} else {
						// TODO: handle "product already added" modal.
						console.log('Already in Cart.');
					}
				});

				
			}
		}
	)
}
</script>


