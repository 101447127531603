var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bulk-add" }, [
    _vm.selection.length != _vm.products.length
      ? _c(
          "button",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.select("all")
              },
            },
          },
          [_vm._v("\n\t\tSelect All\n\t")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.selection.length == _vm.products.length
      ? _c(
          "button",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.select("none")
              },
            },
          },
          [_vm._v("\n\t\tDeselect All\n\t")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        attrs: { disabled: !_vm.selection.length },
        on: {
          click: function ($event) {
            return _vm.select("invert")
          },
        },
      },
      [_vm._v("\n\t\tInvert Selection\n\t")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        attrs: { disabled: !_vm.selection.length },
        on: { click: _vm.cartAdd },
      },
      [
        _vm._v("\n\t\tAdd "),
        _vm.selection.length
          ? _c("span", [
              _vm._v(_vm._s(_vm.selection.length) + " Selected Products"),
            ])
          : _vm._e(),
        _vm._v(" to Label Cart\n\t"),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        attrs: { disabled: !_vm.selection.length },
        on: { click: _vm.sheetAdd },
      },
      [
        _vm._v("\n\t\tAdd "),
        _vm.selection.length
          ? _c("span", [
              _vm._v(_vm._s(_vm.selection.length) + " Selected Products"),
            ])
          : _vm._e(),
        _vm._v(" to Product Sheet\n\t"),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }