var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container-wrapper" }, [
    _c("div", {
      class: _vm.show ? "backdrop active" : "backdrop",
      on: { click: _vm.closeModal },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.show ? "modal modal-open" : "modal",
        on: { click: _vm.closeModal },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary modal-close",
                on: { click: _vm.closeModal },
              },
              [_vm._v("×")]
            ),
            _vm._v(" "),
            _vm.header
              ? _c("div", {
                  staticClass: "modal-header",
                  domProps: { innerHTML: _vm._s(_vm.header) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", {
              staticClass: "modal-body",
              domProps: { innerHTML: _vm._s(_vm.body) },
            }),
            _vm._v(" "),
            _vm.footer == "confirm-duplicate"
              ? _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.closeModal()
                        },
                      },
                    },
                    [_vm._v("No")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.handleDuplicate()
                        },
                      },
                    },
                    [_vm._v("Yes")]
                  ),
                ])
              : _vm.footer
              ? _c("div", {
                  staticClass: "modal-footer",
                  domProps: { innerHTML: _vm._s(_vm.footer) },
                })
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }