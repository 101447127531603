var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.show ? "notification active" : "notification" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary notification-close",
          on: { click: _vm.closeNotification },
        },
        [_vm._v("×")]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { class: _vm.content.length > 1 ? "messages multiple" : "messages" },
        _vm._l(_vm.content, function (message) {
          return _c("li", [_c("p", [_vm._v(_vm._s(message))])])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "notification-timer" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }