<template>
	<div class="order-detail" v-if="order_id">
		<div class="subhead">
			<h2 v-if="Object.keys(order).length">
				{{$niceCart(order.cart_type)}}
				Order
				<span>{{$date(order.created_at)}}</span>

				<small v-if="order.name">
					{{order.name}}
				</small>
			</h2>

			<div class="input-group">
				<a class="btn" @click="$emit('duplicate', {order: order, products: order_products})">
					Duplicate
				</a>

				<a :href="'/orders/' + type +  'sheet/pdf/' + order.id" target="_blank" class="btn">PDF</a>
			</div>
		</div>

		<div class="account" v-if="order && order.account_id">
			<div class="account-details">
				<p v-if="order.account">
					{{order.account.name}}
				</p>

				<address class="address"
					v-html="$formatAddress(order)">
				</address>
			</div>

			<div class="account-no-contact"
				v-if="!order.contact_id">
				<p>Contact not found.</p>
			</div>

			<div class="account-contact" v-else>
				<p>{{order.first_name}} {{order.last_name}}</p>
				<p>{{order.email}}</p>
				<p>{{order.title}}</p>
			</div>

			<div class="account-footer"></div>

		</div>

		<div class="table-wrapper order-history">
			<table v-if="order_products.length">
				<thead>
					<tr>
						<th class="min-175">Product</th>
						<th>Description</th>
						<th>
							<span v-if="order.cart_type == 'label'">Quantity</span>
							<span v-if="order.cart_type == 'pdf'">Note</span>
						</th>
						<th class="min-200">Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="product in order.order_products">
						<td :data-name="product.name">
							{{product.name}}
						</td>
						<td>{{product.description}}</td>
						<td>
							<span v-if="product.qty">{{product.qty}}</span>
							<span v-if="product.note">Note: {{product.note}}</span>
						</td>
						<td>
							<router-link :to="{ path: '/product/' + product.id }" class="btn">
								Go To Product
							</router-link>
						</td>
					</tr>
				</tbody>
			</table>
		</div>


	</div>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'orderDetail',
	data: function() {
		return {
			order_id: ''
		}
	},
	computed: Object.assign(
		mapGetters({
			getHistory: 'getHistory'
		}),
		{
			type: function() {
				return this.order.cart_type == 'label' ? 'label' : 'product';
			},
			order: function() {
				// console.log(this.order_id);
				if (this.getHistory.length) {
					return this.getHistory.filter(order => order.id == this.order_id)[0];
				}
				return {};
			},
			order_products: function() {
				if (Object.keys(this.order).length) {
					// console.log('Pulling product objects', this.order);
					return this.order.order_products.map(item => item.product);
				}
				return [];
			}
		}
	),
	mounted: function() {
		var self = this;

		this.order_id = this.$route.params.id;

		var ids = [];

		this.fetchHistory();

		// this.order.items.forEach(function(product) {
		// 	ids.push(product.product_id);
		// });

		// this.fetchProductById(ids);
	},
	methods: Object.assign(
		mapActions({
			fetchProductById: 'fetchProductById',
			fetchHistory: 'fetchHistory'
		}),
		mapGetters({
			getProducts: 'getProducts'
		}),
		{
			filterName: function(history_id) {
				if (this.order.order_products.length) {
					return this.order.order_products.filter(pr => pr.id == history_id)[0].name;
				}
				return false;
			}
		}
	)
}
</script>


