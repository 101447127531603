<template>
	<div class="modal-container-wrapper">
		<div :class="show ? 'backdrop active': 'backdrop'" @click="closeModal"></div>

		<div :class="show ? 'modal modal-open': 'modal'" @click="closeModal">
			<div class="modal-dialog"><div class="modal-content">
				<button class="btn btn-secondary modal-close" @click="closeModal">&times;</button>

				<div class="modal-header" v-if="header" v-html="header"></div>

				<div class="modal-body" v-html="body"></div>

				<div class="modal-footer" v-if="footer == 'confirm-duplicate'">
					<a class="btn" @click="closeModal()">No</a>
					<a class="btn" @click="handleDuplicate()">Yes</a>
				</div>
				<div class="modal-footer" v-else-if="footer" v-html="footer"></div>
			</div></div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'modal',
	props: [],
	data: function() {
		return {
			show: false,
			header: '',
			body: '',
			footer: '',
			orderObject: {}
		}
	},
	mounted: function() {
		var self = this;
	},
	methods: {
		closeModal() {
			var self = this;

			for (var prop in this.data) {
				if (typeof self[prop] === 'string') {
					self[prop] = '';
				}
			}

			this.show = false;
		},
		openModal(data) {
			var self = this;

			for (var prop in data) {
				if (typeof self[prop] !== 'undefined') {
					self[prop] = data[prop];
				}
			}

			this.show = true;
		},
		handleDuplicate() {
			var self = this;
			this.$parent.handleDuplicate(self.orderObject, true);
			this.closeModal();
		}
	}
}
</script>


