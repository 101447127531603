<template>
	<div class="product-page">
		<p v-if="!product && !fetching">Something went wrong</p>

		<!-- <breadcrumb :breadcrumbs="context"></breadcrumb> -->

		<div class="product-details">
			<product class="product"
				v-if="product"
				@update="handleUpdate"
				@modal="handleModal"
				:product="product"
				:catId="catId">
			</product>
		</div>
	</div>
</template>

<script>
import product from "../components/Product.vue";
import breadcrumb from "../components/Breadcrumb.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'productDetails',
	components: {
		breadcrumb,
		product
	},
	data: function() {
		return {
			id: 0,
			catId: 0,
			context: [],
			fetching: false
		}
	},
	computed: Object.assign(
		mapGetters({}),
		{
			category: function() {
				// return this.getCategoryById()(this.getCategoryIdByProductId()(this.id))[0];
				return this.getCategoryById()(this.product.group_id);
			},
			product: function() {
				return this.getProductsById()(this.id)[0] || false;
			}
		}
	),
	mounted: function() {
		var self = this;

		this.id = this.$route.params.id;

		if (!this.product) {
			this.fetching = true;
			var req = this.fetchProductById(this.id);
			req.finally(function() {
				self.fetching = false;
			});
		}

		if (this.category) {
			// I suspect that, because of architectural changes in
			// how VueX retreives category object that this will not 
			// work. However, since <breadcrumb> is currently disabled,
			// there's no compelling reason to fix it. 
			// 
			// In order to reactivate breadcrumbs (which uses this.context)
			// you will need to troubleshoot. 
			// 
			// - CJ, Jan 2023.

			this.catId = this.category.id;

			this.context = [{
				name: 'Home',
				path: '/'
			}, {
				name: 'All Products',
				path: '/products'
			}, {
				name: this.category.name,
				path: '/categories/' + this.category.name
			}, {
				name: this.product.name,
				path: false
			}];
		}
	},
	watch: {
		$route (to, from) {
			this.updateProduct(to.params.id);
		}
	},
	methods: Object.assign(
		mapActions({
			fetchProductById: 'fetchProductById'
		}),
		mapMutations({
		}),
		mapGetters({
			// getCategoryIdByProductId: 'getCategoryIdByProductId',
			getCategoryById: 'getCategoryById',
			getProductsById: 'getProductsById'
		}),
		{
			updateProduct: function(id) {
				var self = this;

				this.id = id;
				this.fetching = true;
				var req = this.fetchProductById(this.id);
				req.finally(function() {
					self.fetching = false;
				});
				
			},
			handleUpdate: function(obj) {
				this.$emit('update', obj);
			},
			handleModal: function(arg) {
				this.$emit('modal', arg);
			}
		}
	)
}
</script>


