var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrapperClass },
    [
      _vm.header
        ? _c("h2", [_vm._v(_vm._s(_vm.header))])
        : _c("h2", [_vm._v("All Products")]),
      _vm._v(" "),
      _c("div", { staticClass: "product-results" }, [
        _c("h2", [
          _vm._v(
            _vm._s(_vm.products.length ? _vm.products.length : "No ") +
              " Result"
          ),
          _vm.products.length != 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.isMounted
        ? _c("search", {
            attrs: {
              term: _vm.$route.params.query,
              autocompleteDisabled: false,
              catName: _vm.header,
              catId: _vm.catId,
            },
            on: { "update-search": _vm.updateSearch },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.products
        ? _c("bulk-add", {
            attrs: { products: _vm.products, selection: _vm.selection },
            on: { update: _vm.handleUpdate, select: _vm.handleSelect },
          })
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.filters_meta).length
        ? _c("filters", {
            attrs: { filters_meta: _vm.filters_meta },
            on: { select: _vm.handleSelect },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.products, function (product) {
        return _c(
          "div",
          { key: product.id, staticClass: "product-row" },
          [
            _c("div", { staticClass: "bulk-add-selector" }, [
              _c("input", {
                attrs: {
                  type: "checkbox",
                  name: "product-" + product.id,
                  "product-id": product.id,
                },
                on: {
                  change: function ($event) {
                    return _vm.updateSelected($event, product.id)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("product", {
              staticClass: "product",
              attrs: {
                highlight: _vm.highlight,
                product: product,
                catId: _vm.catId,
              },
              on: { update: _vm.handleUpdate, modal: _vm.handleModal },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }