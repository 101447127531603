<template>
	<div :class="'filter-group-container' + (active ? ' active' : '')" :id="id">

		<label :data-attr="id">
			<!-- <input type="checkbox" :checked="active"> -->
			{{$niceAttr(id)}}
			<span v-if="products_range">({{products_range}})</span>
			<span v-else-if="filter.products">({{filter.products}})</span>
		</label>

		<div class="filter-range">
			<div class="range">
				<input class="lower-bound" 
					type="number" 
					:step="incr" 
					:min="filter.min" 
					:max="filter.max" 
					v-model="val.min" 
					:placeholder="filter.min">

				<input class="upper-bound" 
					type="number" 
					:step="incr" 
					:min="filter.min" 
					:max="filter.max" 
					v-model="val.max" 
					:placeholder="filter.max">

				<input type="submit" 
					class="submit-range btn btn-primary" 
					value="Filter" 
					@click="updateFilter()">
			</div>

			<div class="labels-container">
				<label class="label-min">{{filter.min.toFixed(2)}}</label>

				<label class="label-max">{{filter.max.toFixed(2)}}</label>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'filter-range',
	props: ['filter', 'id', 'update'],
	data: function() {
		return {
			incr: 1,
			move: {
				min: false,
				max: false
			},
			start: {
				min: 0,
				max: 0
			},
			val: {
				min: null,
				max: null
			}
		}
	},
	mounted: function() {
		var self = this;

		var range = (this.filter.max - this.filter.min);
		var step = (range / 10);

		if (range <= 2) {
			this.incr = step.toFixed(2);
		} else if (range <= 5) {
			this.incr = step.toFixed(1);
		} else {
			this.incr = step.toFixed(0);
		}
	},
	computed: {
		range: function() {
			return (this.filter.max - this.filter.min);
		},
		products_range: function() {
			return this.filter.range;
		},
		filters: function() {
			return this.getFilters();
		},
		active: function() {
			return !!this.filters[this.id];
		}
	},
	methods: Object.assign(
		mapActions({
			fetchProducts: 'fetchProducts',
			mergeFilters: 'mergeFilters',
		}),
		mapGetters({
			getFilters: 'getFilters',
		}),
		{
			updateFilter: function() {
				var self = this;

				var min = this.val.min || this.filter.min;
				var max = this.val.max || this.filter.max;

				var arg = {
					type: 'in', 
					attribute: self.id, 
					data: [
						parseFloat(min), 
						parseFloat(max)
					]
				}

				console.log('Update Filter', arg);

				// return;

				this.$emit('sliderUpdate', arg.attribute);

				var update = {};

				update[arg.attribute] = {type: arg.type, data: arg.data};

				this.mergeFilters(update);

				this.fetchProducts();
			},
			between: function(x, min, max) {
				return Math.max(Math.min((x), max), min);
			}
		}
	)
}
</script>