var Cart = {
	state: {
		cart: [],
        labels_name: '',
        labels_uuid: ''
	},
	getters: {
        getCart(state) {
            return state.cart;
        },
        getCartData(state) {
            return {
                name: state.labels_name, 
                uuid: state.labels_uuid
            };
        }
    },
    mutations: {
        setCart(state, data) {
            state.cart = data;
        },
        setCartName(state, data) {
            state.labels_name = data;
        },
        setCartUuid(state, data) {
            state.labels_uuid = data;
        },
        addToCart(state, data) {
            // console.log('Add to cart', data);
            var quantity = 0;
            var updatedQuantity = 0;
            var already_in_cart = state.cart.filter(
                (item) => item.id == data.product.id
            );

            if (!state.cart.length) {
                state.labels_uuid = window.$uuid();
            }

            quantity += already_in_cart.length
                ? Number(already_in_cart[0].qty)
                : 0;
            quantity += Number(data.quantity);

            if (already_in_cart.length) {
                state.cart.forEach(function (item) {
                    if (item.id == data.product.id) {
                        item.qty = quantity;
                        updatedQuantity = quantity;
                    }
                });
                // this.$emit("update", "cart");
                data.message = data.product.name + ' quantity updated to ' + updatedQuantity;
                return;
            }

            var res = {
                id: data.product.id,
                part_number: data.product.part_number,
                description: data.product.description,
                image: data.product.image,
                name: data.product.name,
                qty: quantity,
            };

            state.cart.push(res);

            // console.log('Add to Label Cart', state.labels_uuid, state.cart);

            data.message = data.product.name + ' added to cart.';
        },
        removeFromCart(state, data) {
            state.cart = state.cart.filter(product => product.id != data.id);
        }
    },
    actions: {
        postCart(context, form_data) {
            var data = {
                order_type: form_data.type || 'cart',
                cart_type: 'label',
                uuid: context.state.labels_uuid,
                name: context.state.labels_name,
                account: form_data.account,
                saveModeAccount: form_data.saveModeAccount,
                saveModeContact: form_data.saveModeContact,
                items: context.state.cart
            }

            return axios.post('/api/orders', data);
        }
    }
}

export default Cart;


