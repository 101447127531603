export default {
    state: {
        sheet: [],
        sheet_name: '',
        sheet_uuid: ''
    },
    getters: {
        getSheet(state) {
            return state.sheet;
        },
        getSheetData(state) {
            return {
                name: state.sheet_name,
                uuid: state.sheet_uuid
            };
        }
    },
    mutations: {
        setSheet(state, data) {
            state.sheet = data;
        },
        setSheetName(state, data) {
            state.sheet_name = data;
        },
        setSheetUuid(state, data) {
            state.sheet_uuid = data;
        },
        addToSheet(state, data) {
            var already_in_sheet = state.sheet.filter(
                (item) => item.id == data.id
            );

            if (!state.sheet.length) {
                // Generate new UUID for this sheet.
                state.sheet_uuid = window.$uuid();
            }

            // console.log('Add To Sheet:', state.sheet_uuid, data);

            if (already_in_sheet.length) {
                data.message = data.name + ' already in Product Sheet';
                return;
            }

            state.sheet.push(data);

            data.message = data.name + ' added to Product Sheet.';
        },
        removeFromSheet(state, data) {
            state.sheet = state.sheet.filter(product => product.id != data.id);
            data.message = data.name + ' removed from Product Sheet.';
        },
        updateNote(state, data) {
            state.sheet.forEach((product) => {
                if (product.id == data.id) {
                    product.note = data.note;
                }
            });
        }
    },
    actions: {
        postSheet(context, form_data) {
            var data = {
                order_type: form_data.type || 'cart',
                cart_type: 'pdf',
                uuid: context.state.sheet_uuid,
                name: context.state.sheet_name,
                account: form_data.account,
                saveModeAccount: form_data.saveModeAccount,
                saveModeContact: form_data.saveModeContact,
                items: context.state.sheet
            }

            return axios.post('/api/orders', data);
        },
        postPrintOrder(context, form_data) {
            var data = {
                order_type: 'full_print',
                cart_type: 'pdf',
                account: form_data.account,
                saveModeAccount: form_data.saveModeAccount,
                saveModeContact: form_data.saveModeContact,
                alternates_include: form_data.alternates_include,
                items: []
            }

            return axios.post('/api/fullprintorder', data);
        }
    }
};
