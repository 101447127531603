var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-page" }, [
    !_vm.product && !_vm.fetching
      ? _c("p", [_vm._v("Something went wrong")])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-details" },
      [
        _vm.product
          ? _c("product", {
              staticClass: "product",
              attrs: { product: _vm.product, catId: _vm.catId },
              on: { update: _vm.handleUpdate, modal: _vm.handleModal },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }