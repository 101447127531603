<template>
	<div :id="'product-' + product.id">
		<div :class="image ? 'product-info' : 'product-info full'">
			<h1 v-if="!product">Something went wrong</h1>

			<div class="product-header" :data-id="product.id">
				<h2><span data-search="field">{{product.name}}</span></h2>
				<div class="product-actions">
					<a href="javascript:void(0)" class="btn" v-on:click="sheetAdd()">Add to PDF</a>

					<div class="inp-btn">
						<input type="number" v-model="qty" min="1" :name="'product-' + product.id" class="btn">
						<a href="javascript:void(0)" class="btn with-number" v-on:click="cartAdd()">
							Labels
						</a>
					</div>
				</div>
			</div>

			<!-- <p>Part Number: <span data-search="field">{{ product.part_number }}</span></p> -->
			<p v-if="product.description">
				<strong>Notes:</strong>
				<span data-search="field">{{ product.description }}</span>
			</p>

			<table v-if="attributes && Object.keys(attributes).length" class="stripe">
				<thead>
					<tr>
						<th>Name</th>
						<th>Value</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(val, attr) in attributes"
						v-if="val">
						<!-- <td v-for="(val, name) in attribute">{{ name }}</td>
						<td v-for="(val, name) in attribute"><span data-search="field">{{ val }}</span></td> -->
						<td :data-attr="attr">{{$niceAttr(attr)}}</td>
						<td><span data-search="field">{{ val }}</span></td>
					</tr>
				</tbody>
			</table>

			<div v-else>
				<p>No Attributes found.</p>
			</div>

			<strong v-if="alternates && alternates.length">
				Alternative Stryker Parts
			</strong>

			<table v-if="alternates && alternates.length" class="stripe">
				<thead>
					<tr>
						<th>Relationship</th>
						<th>Part Number</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="part in alternates">
						<td><span data-search="field">{{part.relationship}}</span></td>
						<td>
							<router-link :to="{path: '/product/' + part.id}">
								<span data-search="field">{{part.name}}</span>
							</router-link>
						</td>
					</tr>
				</tbody>
			</table>

			<strong v-if="competitors && competitors.length">
				Competitor Replacements
			</strong>

			<table v-if="competitors && competitors.length" class="stripe">
				<thead>
					<tr>
						<th>Brand</th>
						<th>Type</th>
						<th>Part Number</th>
						<th>Name</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="part in competitors">
						<td><span data-search="field">{{part.brand}}</span></td>
						<td><span data-search="field">{{part.type}}</span></td>
						<td><span data-search="field">{{part.part_no}}</span></td>
						<td><span data-search="field">{{part.name}}</span></td>
						<td><span data-search="field">{{part.desc}}</span></td>
					</tr>
				</tbody>
			</table>

			<router-link class="btn"
				v-if="$route.name != 'product-details'"
				:to="{path: '/product/' + product.id}">
				Details
			</router-link>
		</div>

		<div v-if="image" class="product-images">
			<figure v-if="image">
				<button class="btn btn-secondary product-image-zoom"
					@click="modal({
						command: 'open',
						data: {
							header: '<h2>' + product.name + '</h2>',
							body: '<figure><img src=\'' + image + '\' /></figure>',
							footer: false
						}
					});">

					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50">
						<path d="M35.96,32.03l-4.02-1.14c2.92-3.18,4.71-7.41,4.71-12.06C36.65,9,28.66,1,18.83,1S1,9,1,18.83s8,17.83,17.83,17.83 c4.64,0,8.88-1.79,12.06-4.71l1.14,4.02L45.06,49l1.51-1.51l0.92-0.92L49,45.06L35.96,32.03z M18.83,31.08 c-6.76,0-12.26-5.5-12.26-12.26s5.5-12.26,12.26-12.26s12.26,5.5,12.26,12.26S25.58,31.08,18.83,31.08z"/>
					</svg>
				</button>

				<img :src="image">
			</figure>

			<figure class="product-image-thumbs"
				v-if="thumbs.length && $route.name == 'product-details'">
				<img v-for="img in thumbs" :src="img.thumb" />
			</figure>


		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

// import Modal from "../components/Modal.vue";

export default {
	name: 'product',
	props: ['product', 'catId', 'highlight'],
	// components: {Modal},
	data: function() {
		return {
			isDetails: false,
			// name: "",
			// part_number: "",
			// description: "",
			qty: 1,
			attributes: {},
			competitors: [],
			alternates: [],
			image: '',
			thumbs: [],
		};
	},
	computed: Object.assign(
		mapGetters({}),
		{
			cart() {
				return this.getCart();
			}
		}
	),
	mounted: function () {
		if (!this.product) {
			return;
		}

		var self = this;

		// console.log('Product', this.product);

		// this.attributes = this.getAttributesById()(this.product.id) || [];
		this.attributes = {};

		for (var attr in this.product.product_measurement) {
			if (
				attr == 'id' || 
				attr == 'product_id' ||
				attr == 'created_at' ||
				attr == 'updated_at'
			) {
				continue;
			}
			if (!!this.product.product_measurement[attr]) {
				this.attributes[attr] = this.product.product_measurement[attr];
			}
		}

		var comp_obj = {};

		this.product.competitor_parts.forEach(function(part) {
			self.competitors.push({
				brand: part.competitor_brands.name, 
				type: part.pivot.relationship,
				part_no: part.part_no,
				name: part.name,
				desc: part.description,
			});
		});

		this.product.alternate_parts.forEach(function(part) {
			var relationship = part.pivot.relationship == 'alternate' ? 'Alternate' : 'Replace';

			if (relationship == 'Replace') {
				relationship += part.pivot.alternate_product_id == part.id ? 's' : 'd by';
			}

			self.alternates.push({
				id: part.id,
				name: part.name,
				relationship: relationship
			})
		});

		self.alternates.sort(function(a, b) {
			// Move New Products ("Replaced by") to the top.
			return a.relationship == 'Replaces';
		})

		this.competitors.sort(function(a, b) {
			// Exact match first, else Alphabetical.
			return b.type == 'exact match' ? 1 : a.type > b.type;
		});

		this.image = '';

		if (window.location.href.indexOf('http://127.0.0.1:8000') > -1) {
			this.image += 'https://stryker-cutting.ddmdev.com';
		}

		this.image += this.product.image;

		if (this.highlight) {
			this.$nextTick(function() {
				this.highlightText(this.highlight);
			});
		}
	},
	watch: {
		highlight: {
			handler: function(str) {
				this.highlightText(str);
			}
		}
	},
	methods: Object.assign(
		mapMutations({
			addToCart: 'addToCart',
			addToSheet: 'addToSheet'
		}),
		mapGetters({
			getCart: "getCart",
			getSheet: 'getSheet',
			getAttributesById: "getAttributesById"
		}),
		{
			modal: function(arg) {
				this.$emit('modal', arg);
			},
			highlightText: function(str) {
				var nodes = document.querySelectorAll('#product-' + this.product.id + ' [data-search="field"]');

				nodes.forEach(function(node) {
					var exp = new RegExp(str, 'gi');

					node.innerHTML = node.innerText.replace(exp, '<strong class="highlight">' + str + '</strong>');
				});
			},
			cartAdd: function() {
				var data = {product: this.product, quantity: this.qty}

				this.addToCart(data);

				this.$emit('update', {type: 'cart', message: data.message});
			},
			sheetAdd: function() {
				// Submit a subset of product information to the Sheet Cart.
				//
				// Possibly more performant, but importantly bundles product information
				// as it exists right now for the purpose of maintaining "order"
				// history.

				var data = {
					id: this.product.id,
					image: this.image ? this.image : false,
					description: this.product.description,
					attributes: this.attributes,
					name: this.product.name,
					note: {},
				}

				this.addToSheet(data);

				this.$emit('update', {type:'sheet', message: data.message});
			},
		}
	),
};
</script>


