<template>
	<div :class="show ? 'notification active': 'notification'">
		<button class="btn btn-secondary notification-close" @click="closeNotification">&times;</button>

		<ul :class="content.length > 1 ? 'messages multiple' : 'messages'">
			<li v-for="message in content">
				<p>{{message}}</p>
			</li>
		</ul>

		<div class="notification-timer"></div>
	</div>
</template>

<script>
let notificationTimeout;

export default {
	name: 'notification',
	props: [],
	data: function() {
		return {
			show: false,
			content: []
		}
	},
	mounted: function() {
		var self = this;
	},
	methods: {
		closeNotification() {
			var self = this;

			setTimeout(function() {
				self.content = [];
			}, 400);

			this.show = false;
		},
		openNotification(message) {
			var self = this;

			if (!message) {
				return;
			}

			this.content.push(message);

			clearTimeout(notificationTimeout);
			this.show = false;

			setTimeout(function() {
				notificationTimeout = setTimeout(function() {
					self.closeNotification();
				}, 9000);

				self.show = true;
			}, 10);
		}
	}
}
</script>


