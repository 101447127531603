var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about-page" }, [
      _c("h2", [_vm._v("How to Use")]),
      _vm._v(" "),
      _c("div", { staticClass: "video-container" }, [
        _c("h3", [_vm._v("Full Walkthrough")]),
        _vm._v(" "),
        _c(
          "video",
          { attrs: { controls: "", poster: "images/walkthrough.png" } },
          [
            _c("source", {
              attrs: {
                src: "video/Cutting Accessories - Full Walkthrough v2-Broadband Low-1.mp4",
                type: "video/mp4",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "video-container" }, [
        _c("h3", [_vm._v("Topic: Product Search")]),
        _vm._v(" "),
        _c(
          "video",
          { attrs: { controls: "", poster: "images/product-search.png" } },
          [
            _c("source", {
              attrs: {
                src: "video/search-functionality.mp4",
                type: "video/mp4",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "video-container" }, [
        _c("h3", [_vm._v("Topic: Category Filtering")]),
        _vm._v(" "),
        _c(
          "video",
          { attrs: { controls: "", poster: "images/category-filtering.png" } },
          [
            _c("source", {
              attrs: {
                src: "video/Cutting Accessories - 2 Category Filtering-Broadband Low-1.mp4",
                type: "video/mp4",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "video-container" }, [
        _c("h3", [_vm._v("Topic: Product Sheets")]),
        _vm._v(" "),
        _c(
          "video",
          { attrs: { controls: "", poster: "images/product-sheets.png" } },
          [
            _c("source", {
              attrs: { src: "video/product-sheets.mp4", type: "video/mp4" },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "video-container" }, [
        _c("h3", [_vm._v("Topic: Label Ordering")]),
        _vm._v(" "),
        _c(
          "video",
          { attrs: { controls: "", poster: "images/label-ordering.png" } },
          [
            _c("source", {
              attrs: { src: "video/blade-labels.mp4", type: "video/mp4" },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "video-container" }, [
        _c("h3", [_vm._v("Topic: My Hub")]),
        _vm._v(" "),
        _c("video", { attrs: { controls: "", poster: "images/my-hub.png" } }, [
          _c("source", {
            attrs: {
              src: "video/Cutting Accessories - 5 My Hub-Broadband Low-1.mp4",
              type: "video/mp4",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }