
var Contact = {
	state: {
		contacts: []
	},
	getters: {
		getContacts: (state) => {
			return state.contacts;
		},
		getContactsByAccountId: (state) => (id) => {
			return state.contacts.filter(contact => contact.account_id == id);
		},
		getContactById: (state) => (id) => {
			return state.contacts.filter(contact => contact.id == id);
		}
	},
	mutations: {
		setContacts(state, data) {
            state.contacts = data;
        },
        mergeContacts(state, data) {
        	state.contacts.push(data);
        }
	},
	actions: {
		fetchContacts(context, ids) {
			var payload = ids && ids.length ? '?where=' + JSON.stringify({account_id: ids}) : '';

			axios.get('/api/contacts' + payload)
                .then(response => {
                    response.data.data.forEach(contact => {
                    	context.commit('mergeContacts', contact);
                    });
                })
                .catch(error => {
                    console.log('Contact Get Error');
                })
                .finally();
        },
        postContact(context, data) {
            // Probably process `arg` here and maybe
            // slap a user_id onto it?

            // console.log('post Account', String(data.id), data);

            var req = {
                method: (data.id ? 'put' : 'post'),
                tar: (data.id ? '/api/contacts/' + data.id : '/api/contacts')
            }

            // console.log(req);

            // data.user = context.state.user;

            return axios[req.method](req.tar, data);
        }
	}
}

export default Contact;


/*[{
			id: 1,
			accountId: 1,
			firstName: 'James',
			lastName: 'Smith',
			title: 'Purchasing Coordinator',
			email: 'namename@example.com'
		}, {
			id: 2,
			accountId: 1,
			firstName: 'Kevin',
			lastName: 'Thomas',
			title: 'Surgical Inventory Manager',
			email: 'namename@example.com'
		}, {
			id: 3,
			accountId: 1,
			firstName: 'John',
			lastName: 'Doe',
			title: 'Chief of Surgery',
			email: 'namename@example.com'
		}, {
			id: 4,
			accountId: 2,
			firstName: 'James',
			lastName: 'Smith',
			title: 'Purchasing Coordinator',
			email: 'namename@example.com'
		}, {
			id: 5,
			accountId: 3,
			firstName: 'Kevin',
			lastName: 'Thomas',
			title: 'Surgical Inventory Manager',
			email: 'namename@example.com'
		}, {
			id: 6,
			accountId: 3,
			firstName: 'John',
			lastName: 'Doe',
			title: 'Chief of Surgery',
			email: 'namename@example.com'
		}]*/
