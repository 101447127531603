var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-page" }, [
    _c("div", { staticClass: "subhead" }, [
      _c("h2", [_vm._v("Label Order Cart")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _c("label", [_vm._v("Order Name (optional)")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name",
            },
          ],
          attrs: { type: "text", name: "order-name" },
          domProps: { value: _vm.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-wrapper carts label-cart" }, [
      _vm.cart.length
        ? _c("table", [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.cart, function (product) {
                return _c("tr", [
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/product/" + product.id } } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(product.name) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(product.description))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: product.qty,
                          expression: "product.qty",
                        },
                      ],
                      attrs: {
                        type: "number",
                        value: "1",
                        name: "qty-" + product.id,
                      },
                      domProps: { value: product.qty },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(product, "qty", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn",
                        on: {
                          click: function ($event) {
                            return _vm.remove(product.id)
                          },
                        },
                      },
                      [_vm._v("Remove")]
                    ),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _c("div", [
            _c("p", [
              _vm._v("Products added to your Label cart will display here."),
            ]),
          ]),
    ]),
    _vm._v(" "),
    _vm.cart.length
      ? _c("div", { staticClass: "cart-actions" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.clear()
                },
              },
            },
            [_vm._v("Clear Cart")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.cart.length
      ? _c(
          "div",
          [
            _c("h2", [_vm._v("Customer (Shipping Destination)")]),
            _vm._v(" "),
            _c("customerSelectAdd", {
              key: _vm.uuid,
              ref: "customerSelectAdd",
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.cart.length
      ? _c("div", {}, [
          _c("h3", [_vm._v("Submit Order")]),
          _vm._v(" "),
          _c("div", { staticClass: "btn-group" }, [
            _c(
              "a",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v("Submit")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Product")]),
        _vm._v(" "),
        _c("th", [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }