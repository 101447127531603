
export default {
	install: function(Vue, options) {
		Vue.prototype.$date = function(inp) {
			var date = new Date(inp.toString().length == 10 ? inp * 1000 : inp);

			return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
		}

		Vue.prototype.$formatAddress = function(obj) {
			var html = '<p>' + obj.addressOne + '</p>';
			html += obj.addressTwo ? '<p>' + obj.addressTwo + '</p>' : '';
			html += obj.addressThree ? '<p>' + obj.addressThree + '</p>' : '';

			html += '<p>' + obj.city + ', ' + obj.state + '</p>';
			html += '<p>' + obj.zip + '</p>';

			return html;
		}

		Vue.prototype.$kindOfCartObj = function(obj) {
			if (obj.address_id) {
				return 'history';
			}


		}

		Vue.prototype.$uuid = window.$uuid;

		Vue.prototype.$validate = function(model, data) {
			document.querySelectorAll('.validation-error').forEach(function(p) {
				p.remove();
			})

			var config = {
				contact: {
					first_name: /\w+/g,
					last_name: /\w+/g,
					email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				},
				account: {
					name: /\w+/g,
					addressOne: /^\s*\S+(?:\s+\S+){2}/,
					city: /\w+/g,
					state: /\w+/g,
					zip: /\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d/g
				},
				order: {
					first_name: /\w+/g,
					last_name: /\w+/g,
					email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
					name: /\w+/g,
					addressOne: /^\s*\S+(?:\s+\S+){2}/,
					city: /\w+/g,
					state: /\w+/g,
					zip: /\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d/g
				}
			}

			var flags = [];

			for (var attr in config[model]) {
				if (!data[attr]) {
					flags.push({
						attr: attr,
						error: 'This field is required'
					});

					continue;
				}

				if (!config[model][attr].test(data[attr])) {
					flags.push({
						attr: attr,
						error: 'This is invalid'
					});
				}
			}

			flags.forEach(function(flag) {
				var selector = data.suffix ? flag.attr + data.suffix : flag.attr;

				document.querySelectorAll('[data-validate="' + selector + '"]').forEach(function(node) {
					let p = document.createElement('p');
					p.classList.add('validation-error');
					p.textContent = flag.error;
					node.appendChild(p);
				})
			})

			return flags;
		}

		Vue.prototype.$niceAttr = function(str) {
			let config = {
				attachment_type: 'Attachment type',
				bit_size: 'Bit size',
				blade_height: 'Blade height',
				blade_type: 'Blade type',
				cut_depth: 'Cut depth',
				cut_edge: 'Cut edge',
				cut_length: 'Cut length',
				diameter: 'Diameter',
				exposure: 'Exposure',
				// fixed_travel_point: 'Fixed Travel Point',
				grit: 'Grit',
				head_diameter: 'Head diameter',
				head_length: 'Head length',
				length: 'Length',
				max_width: 'Max width',
				number_of_flutes: 'Number of flutes',
				offset_thickness: 'Offset thickness',
				rasp_style: 'Rasp style',
				stop_depth: 'Stop depth',
				teeth_per_inch: 'Teeth per inch',
				thickness: 'Thickness',
				total_length: 'Total length',
				replacement: 'Replaces',
				compatible: 'Compatible With'
			};

			return config[str];
		}

		Vue.prototype.$niceCart = function(str) {
			let config = {
				label: 'Label Sheet',
				pdf: 'Product Sheet PDF'
			}

			return config[str];
		}

		Vue.prototype.$clone = function(obj) {
			var data = {};
			for (let key in obj) {
				data[key] = obj[key];
			}

			return data;
		}
	}
}
