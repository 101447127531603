var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c(
        "header",
        { staticClass: "header" },
        [
          _c(
            "router-link",
            { staticClass: "logo", attrs: { to: { name: "home" } } },
            [_c("img", { attrs: { src: "/images/logo.svg" } })]
          ),
          _vm._v(" "),
          _vm.getIsLoggedIn
            ? _c(
                "nav",
                { staticClass: "primary-nav" },
                [
                  _c(
                    "router-link",
                    {
                      class:
                        "nav-item" + (_vm.route == "cart" ? " active" : ""),
                      attrs: { to: { name: "cart" } },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Labels " +
                            _vm._s(
                              _vm.cart.length ? "(" + _vm.cart.length + ")" : ""
                            )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      class:
                        "nav-item" + (_vm.route == "about" ? " active" : ""),
                      attrs: { to: { name: "about" } },
                    },
                    [_c("span", [_vm._v("How to Use")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      class: "nav-item" + (_vm.route == "hub" ? " active" : ""),
                      attrs: { to: { name: "hub" } },
                    },
                    [_c("span", [_vm._v("My Hub")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    { staticClass: "nav-item", on: { click: _vm.logoutUser } },
                    [_c("span", [_vm._v("Logout")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getIsLoggedIn
            ? _c(
                "router-link",
                {
                  class:
                    "top-nav btn" +
                    (_vm.route == "product-sheet" ? " active" : ""),
                  attrs: { to: { name: "sheet" } },
                },
                [
                  _c("span", [
                    _vm._v(
                      "Product Sheet PDF " +
                        _vm._s(
                          _vm.sheet.length ? "(" + _vm.sheet.length + ")" : ""
                        )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._m(0),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-body" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm.config.isMounted
            ? _c("router-view", {
                on: {
                  update: _vm.handleUpdate,
                  modal: _vm.handleModal,
                  notify: _vm.handleNotification,
                  duplicate: _vm.handleDuplicate,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("modal", { ref: "modalContainer" }),
      _vm._v(" "),
      _c("notification", { ref: "notificationContainer" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-bottom" }, [
      _c("h1", [_c("span", [_vm._v("Cutting accessories")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "loading-lock", attrs: { id: "loading-lock" } },
      [_c("img", { attrs: { src: "/images/loading.gif" } })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("p", [_vm._v("For Internal Use Only")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }