<template>
	<div class="search">
		<div :class="'search-input' + (searchSubmit ? ' wide': '')">
			<input v-model="input" 
				   v-on:keyup="query" 
				   v-on:keyup.enter="search" 
				   @focus="show_autocomplete = true"
				   @blur="show_autocomplete = false"
				   class="inp" 
				   type="text" 
				   name="search">

			<a v-if="input" @click="input = ''; query();" class="clear">
				&times;
			</a>

			<a @click="search" class="btn">
				Search <span v-if="searchSubmit">{{searchSubmit}}</span>
			</a>
		</div>

		<div :style="'display: ' + (displayAuto ? ' block;' : ' none;')" class="search-autocomplete">
			<div v-if="!matches.length" class="">
				<small class="notice">
					No <strong>{{type}}</strong> Results.
				</small>
			</div>

			<div v-if="matches.length" class="">
				<a v-for="match in matches.slice(0, 10)"
					v-on:click="route(match.id)">
					<p>
						{{match.arr[0]}}<strong>{{match.arr[1]}}</strong>{{match.arr[2]}}
					</p>
				</a>

				<small class="notice">
					Quick Search 
					<strong>{{type}}</strong> 

					Results
					<span v-if="catId">(within <strong>{{catName}}</strong> Category)</span>
					<strong v-if="matches.length > 10">Showing 1 - 10 of {{matches.length}} Results</strong>
				</small>
			</div>

			<!-- <small class="notice">
				Switch to 
				<a v-if="type != 'Part Number'" @click="switch_type('Part Number')">Part Number</a>
				<a v-if="type != 'Keyword'" @click="switch_type('Keyword')">Keyword</a>.
			</small> -->
		</div>
	</div>
</template>

<script>
import Product from "../models/Product.js";
import {mapGetters} from "vuex";

export default {
	name: 'search',
	props: ['catId', 'catName', 'term', 'autocompleteDisabled'],
	data: function() {
		return {
			input: '',
			// part_nos: [],
			show_autocomplete: false,
			matches: [],
			type: 'Part Number'
		}
	},
	mounted: function() {
		var self = this;

		if (this.term) {
			this.input = this.term;
		}
	},
	computed: Object.assign(
		mapGetters({
			partnumbers: "getPartNumbers"
		}),
		{
			searchSubmit: function() {
				// DEV/QA Beta
				// return;

				if (this.$route.name == 'search-page' || this.$route.name == 'home') {
					return;
				}

				if (this.catId) {
					return 'Within';
				}
				if (this.autocompleteDisabled ) {
					return 'Again';
				}
				return;
			},
			displayAuto: function() {
				return this.show_autocomplete &&
					this.input && 
					this.input.length > 2 && 
					!this.autocompleteDisabled;
			}
		}
	),
	methods: Object.assign(
		mapGetters({
			searchPartNumbers: "searchPartNumbers",
			searchNames: "searchNames",
			searchKeyword: "searchKeyword"
		}),
		{
			switch_type: function(type) {
				this.type = type;
				this.query();
			},
			query: function() {
				var self = this;

				var inp = self.input.toLowerCase();

				this.matches = [];

				if (!inp || this.autocompleteDisabled || inp.length < 3) {
					return;
				}

				this.partnumbers
					.filter(num => (num.pn.indexOf(inp) > -1))
					.forEach(function(match) {
						var text = match.pn.toLowerCase().split(inp);
						self.matches.push({id: match.id, arr: [text[0], self.input, text[1]]});
					});

				// Repeat for users who skipped hyphens or other non /\W/ characters.
				this.partnumbers
					.filter(function(num) {
						if (num.pn.indexOf(inp) > -1) {
							return 0;
						}
						if (num.pn.replace(/\W/g, '').indexOf(inp.replace(/\W/g, '')) > -1) {
							return 1;
						}
					})
					.forEach(function(match) {
						var text = match.pn.toLowerCase().split(inp);
						self.matches.push({id: match.id, arr: ['', match.pn, '']});
					});

			},
			multifunctionquery: function() {
				var self = this;

				console.log('Warning. This function is deprecated.');

				var config = {
					'Part Number': 'part_number',
					'Product Name': 'name',
					'Keyword': 'keyword'
				}

				var inp = self.input.toLowerCase();

				this.matches = [];

				if (!inp || this.autocompleteDisabled || inp.length < 3) {
					return;
				}

				var matches = [];

				switch (this.type) {
					case 'Part Number':
						matches = this.searchPartNumbers()(inp);
						break;
					case 'Product Name':
						// This is essentially dormant.
						matches = this.searchNames()(inp);
						break;
					case 'Keyword':
					default: 
						matches = this.searchKeyword()(inp);
						break;
				}

				for (var ind in matches) {
					var match = {
						id: matches[ind].id,
						text: matches[ind][config[this.type]].toLowerCase().split(inp)
					}

					var res = [
						match.text[0],
						self.input
					];

					match.text.splice(0, 1);

					if (this.type == 'Keyword') {
						res[0] = matches[ind].name.substring(0, 30) + res[0];
					}

					res.push(match.text.join(inp));

					self.matches.push({id: match.id, arr: res});
				}
			},
			search: function() {
				var r = this.$route.name == 'search-results',
					p = this.$route.name == 'search-page',
					c = this.$route.name == 'products-by-category-name',
					i = this.input,
					q = this.$route.params.query;

				// console.log(r, p, i, !!q);

				if (
					((r || p || c) && i) || 
					(r && !i && q)
				) {
					this.$emit('update-search', this.input);
				}

				if (
					(!r && !p) || 
					(!!q && i != q) ||
					(i && i != q) || 
					(r && i != q)
				) {
					this.$router.push({
						path: '/search/' + i
					});
				}
			},
			route: function(id) {
				// var id = Product.get_by_part_number(part_no)[0].id;

				this.$router.push({
					path: '/product/' + id
				})
			}
		}
	)
}
</script>


