var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("h2", [_vm._v("Search")]),
      _vm._v(" "),
      _c("search", { attrs: { autocompleteDisabled: true } }),
      _vm._v(" "),
      _c("h2", [_vm._v("\n\t\tProduct categories\n\t")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "categories" },
        _vm._l(_vm.categories, function (category) {
          return _c("category", {
            key: category.id,
            attrs: { category: category },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }