var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order_id
    ? _c("div", { staticClass: "order-detail" }, [
        _c("div", { staticClass: "subhead" }, [
          Object.keys(_vm.order).length
            ? _c("h2", [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.$niceCart(_vm.order.cart_type)) +
                    "\n\t\t\tOrder\n\t\t\t"
                ),
                _c("span", [_vm._v(_vm._s(_vm.$date(_vm.order.created_at)))]),
                _vm._v(" "),
                _vm.order.name
                  ? _c("small", [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.order.name) + "\n\t\t\t"
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "input-group" }, [
            _c(
              "a",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("duplicate", {
                      order: _vm.order,
                      products: _vm.order_products,
                    })
                  },
                },
              },
              [_vm._v("\n\t\t\t\tDuplicate\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn",
                attrs: {
                  href: "/orders/" + _vm.type + "sheet/pdf/" + _vm.order.id,
                  target: "_blank",
                },
              },
              [_vm._v("PDF")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.order && _vm.order.account_id
          ? _c("div", { staticClass: "account" }, [
              _c("div", { staticClass: "account-details" }, [
                _vm.order.account
                  ? _c("p", [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.order.account.name) +
                          "\n\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("address", {
                  staticClass: "address",
                  domProps: {
                    innerHTML: _vm._s(_vm.$formatAddress(_vm.order)),
                  },
                }),
              ]),
              _vm._v(" "),
              !_vm.order.contact_id
                ? _c("div", { staticClass: "account-no-contact" }, [
                    _c("p", [_vm._v("Contact not found.")]),
                  ])
                : _c("div", { staticClass: "account-contact" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.order.first_name) +
                          " " +
                          _vm._s(_vm.order.last_name)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.order.email))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.order.title))]),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "account-footer" }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "table-wrapper order-history" }, [
          _vm.order_products.length
            ? _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "min-175" }, [_vm._v("Product")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Description")]),
                    _vm._v(" "),
                    _c("th", [
                      _vm.order.cart_type == "label"
                        ? _c("span", [_vm._v("Quantity")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.order.cart_type == "pdf"
                        ? _c("span", [_vm._v("Note")])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "min-200" }, [_vm._v("Actions")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.order.order_products, function (product) {
                    return _c("tr", [
                      _c("td", { attrs: { "data-name": product.name } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(product.name) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(product.description))]),
                      _vm._v(" "),
                      _c("td", [
                        product.qty
                          ? _c("span", [_vm._v(_vm._s(product.qty))])
                          : _vm._e(),
                        _vm._v(" "),
                        product.note
                          ? _c("span", [
                              _vm._v("Note: " + _vm._s(product.note)),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn",
                              attrs: { to: { path: "/product/" + product.id } },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tGo To Product\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }