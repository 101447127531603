var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.noclass ? "" : "account" },
    [
      _c("div", { staticClass: "account-details" }, [
        _c("p", [_vm._v("\n\t\t\t" + _vm._s(_vm.account.name) + "\n\t\t")]),
        _vm._v(" "),
        _c("address", {
          staticClass: "address",
          domProps: { innerHTML: _vm._s(_vm.$formatAddress(_vm.account)) },
        }),
      ]),
      _vm._v(" "),
      _vm.account
        ? _c(
            "div",
            { staticClass: "account-actions" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn",
                  attrs: { to: { path: "/edit/account/" + _vm.account.id } },
                },
                [_vm._v("\n\t\t\tEdit\n\t\t")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.contacts.length
        ? _c("div", { staticClass: "account-no-contact" }, [
            _c("p", [_vm._v("No contacts found.")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.contacts, function (contact) {
        return _c("div", { staticClass: "account-contact" }, [
          _c("p", [
            _vm._v(
              _vm._s(contact.first_name) + " " + _vm._s(contact.last_name)
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(contact.email))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(contact.title))]),
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "account-footer" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }