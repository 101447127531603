var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search" }, [
    _c("div", { class: "search-input" + (_vm.searchSubmit ? " wide" : "") }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input,
            expression: "input",
          },
        ],
        staticClass: "inp",
        attrs: { type: "text", name: "search" },
        domProps: { value: _vm.input },
        on: {
          keyup: [
            _vm.query,
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.search.apply(null, arguments)
            },
          ],
          focus: function ($event) {
            _vm.show_autocomplete = true
          },
          blur: function ($event) {
            _vm.show_autocomplete = false
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.input = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.input
        ? _c(
            "a",
            {
              staticClass: "clear",
              on: {
                click: function ($event) {
                  _vm.input = ""
                  _vm.query()
                },
              },
            },
            [_vm._v("\n\t\t\t×\n\t\t")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("a", { staticClass: "btn", on: { click: _vm.search } }, [
        _vm._v("\n\t\t\tSearch "),
        _vm.searchSubmit
          ? _c("span", [_vm._v(_vm._s(_vm.searchSubmit))])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "search-autocomplete",
        style: "display: " + (_vm.displayAuto ? " block;" : " none;"),
      },
      [
        !_vm.matches.length
          ? _c("div", {}, [
              _c("small", { staticClass: "notice" }, [
                _vm._v("\n\t\t\t\tNo "),
                _c("strong", [_vm._v(_vm._s(_vm.type))]),
                _vm._v(" Results.\n\t\t\t"),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.matches.length
          ? _c(
              "div",
              {},
              [
                _vm._l(_vm.matches.slice(0, 10), function (match) {
                  return _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.route(match.id)
                        },
                      },
                    },
                    [
                      _c("p", [
                        _vm._v("\n\t\t\t\t\t" + _vm._s(match.arr[0])),
                        _c("strong", [_vm._v(_vm._s(match.arr[1]))]),
                        _vm._v(_vm._s(match.arr[2]) + "\n\t\t\t\t"),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("small", { staticClass: "notice" }, [
                  _vm._v("\n\t\t\t\tQuick Search \n\t\t\t\t"),
                  _c("strong", [_vm._v(_vm._s(_vm.type))]),
                  _vm._v(" \n\n\t\t\t\tResults\n\t\t\t\t"),
                  _vm.catId
                    ? _c("span", [
                        _vm._v("(within "),
                        _c("strong", [_vm._v(_vm._s(_vm.catName))]),
                        _vm._v(" Category)"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.matches.length > 10
                    ? _c("strong", [
                        _vm._v(
                          "Showing 1 - 10 of " +
                            _vm._s(_vm.matches.length) +
                            " Results"
                        ),
                      ])
                    : _vm._e(),
                ]),
              ],
              2
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }