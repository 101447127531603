var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.breadcrumbs, function (link, ind) {
      return _c(
        "div",
        { key: link.name + "-" + ind, staticClass: "breadcrumb-item" },
        [
          ind + 1 != _vm.breadcrumbs.length
            ? _c("router-link", { attrs: { to: { path: link.path } } }, [
                _vm._v("\n\n\t\t\t" + _vm._s(link.name) + "\n\t\t"),
              ])
            : _c("span", [_vm._v("\n\t\t\t" + _vm._s(link.name) + "\n\t\t")]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }