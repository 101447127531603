<template>
	<div class="accounts">
		<header class="subhead">
			<h3>
				My Accounts
			</h3>

			<div class="input-group">
				<label>Search</label>
				<input type="text" name="search" v-model="search">
			</div>
		</header>

		<div class="accounts-grid" v-if="search && filteredAccounts.length">
			<account class="account-card filtered"
				v-for="account in filteredAccounts.slice(0, 6)"
				:account="account"
				:order="false"
				:key="'f-' + account.id"
				:data-selected="account.selected"
				v-on:click.native="handleSelect(account.id)">
			</account>
		</div>
		<div class="accounts-grid" v-else-if="search && !filteredAccounts.length">
			<div class="account">
				No results in {{accounts.length}} account<span v-if="accounts.length != 1">s</span>.
			</div>
		</div>
		<div class="accounts-grid" v-else-if="showAll">
			<account class="account-card"
				v-for="account in accounts"
				:account="account"
				:order="false"
				:key="account.id"
				:data-selected="account.selected"
				v-on:click.native="handleSelect(account.id)">
			</account>

			<div class="account">
				Showing {{accounts.length - 5}} additional account<span v-if="(accounts.length - 5) != 1">s</span>
				<div class="btn-grp">
					<a class="btn" @click="showAll = !showAll">
						Hide
					</a>
				</div>
			</div>
		</div>

		<div class="accounts-grid" v-else>
			<account class="account-card"
				v-for="account in accounts.slice(0, 5)"
				:account="account"
				:order="false"
				:key="account.id"
				:data-selected="account.selected"
				v-on:click.native="handleSelect(account.id)">
			</account>

			<div class="account" v-if="(accounts.length - 5) > 0">
				{{accounts.length - 5}} more account<span v-if="(accounts.length - 5) != 1">s</span>

				<div class="btn-grp">
					<a class="btn" @click="showAll = !showAll">
						Show All
					</a>
				</div>
			</div>
		</div>



	</div>
</template>

<script>
import account from "../../components/Account.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'accountFinder',
	components: {
		account
	},
	data: function() {
		return {
			search: '',
			showAll: false
		}
	},
	mounted: function() {
		var self = this;

		// Always refetch when mounting, in case new account was added since login.
		this.fetchAccounts();

		this.accounts.forEach(function(account) {
			if (account.selected) {
				self.$delete(account, 'selected');
			}
		});
	},
	computed: Object.assign(
		mapGetters({
			accounts: 'getAccounts',
			// contacts: 'getContacts'
		}),
		{
			filteredAccounts: function() {
				var self = this;
				return this.accounts.filter(function(account) {
					var haystack = '';

					for (var key in account) {
						if (typeof account[key] == 'string') {
							haystack += account[key].toLowerCase();
						}
					}

					if (haystack.indexOf(self.search.toLowerCase()) > -1) {
						return true;
					}

					return false;
				});
			}
		}
	),
	methods: Object.assign(
		mapActions({
			fetchAccounts: 'fetchAccounts',
		}),
		{
			handleUpdate: function(obj) {
				this.$emit('update', obj);
			},
			handleModal: function(arg) {
				this.$emit('modal', arg);
			},
			handleSelect: function(id) {
				var self = this,
					account = {};

				this.accounts.forEach(function(acc) {
					if (acc.id == id) {
						acc.selected = acc.selected ? false : true;
						account = acc;
					} else {
						acc.selected = false;
					}
				});

				this.$emit('select', {type: 'Account', val: account});
			}
		}
	)
}
</script>
