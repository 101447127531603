<template>
	<div class="contacts" v-if="account && contacts">
		<header class="subhead" v-if="contacts.length">
			<h3>
				Contacts from {{ account.name }}
			</h3>

			<div class="input-group">
				<label>Search</label>
				<input type="text" name="search" v-model="search">
			</div>
		</header>

		<p v-else>
			No contacts found from {{account.name}}
		</p>


		<div class="contacts-grid" v-if="search && filteredContacts.length">
			<div class="contact contact-card order-card"
				v-for="contact in filteredContacts"
				:data-selected="contact.selected"
				@click="handleSelect(contact)">
				<div class="contact-details">
					<p>{{contact.first_name}} {{contact.last_name}}</p>
					<p>{{contact.title}}</p>
					<p>{{contact.email}}</p>
				</div>
			</div>
		</div>

		<div class="accounts-grid" v-else-if="search && !filteredContacts.length">
			<div class="contact">
				No results in {{contacts.length}} contact<span v-if="contacts.length != 1">s</span>.
			</div>
		</div>

		<div class="contacts-grid" v-else-if="showAll">
			<div class="contact contact-card order-card"
				v-for="contact in contacts"
				:data-selected="contact.selected"
				@click="handleSelect(contact)">
				<div class="contact-details">
					<p>{{contact.first_name}} {{contact.last_name}}</p>
					<p>{{contact.title}}</p>
					<p>{{contact.email}}</p>
				</div>
			</div>
			<div class="account">
				Showing {{contacts.length - 5}} additional contact<span v-if="(contacts.length - 5) != 1">s</span>
				<div class="btn-grp">
					<a class="btn" @click="showAll = !showAll">
						Hide
					</a>
				</div>
			</div>
		</div>

		<div class="contacts-grid" v-else>
			<div class="contact contact-card order-card"
				v-for="contact in contacts.slice(0, 5)"
				:data-selected="contact.selected"
				@click="handleSelect(contact)">
				<div class="contact-details">
					<p>{{contact.first_name}} {{contact.last_name}}</p>
					<p>{{contact.title}}</p>
					<p>{{contact.email}}</p>
				</div>
			</div>

			<div class="account" v-if="(contacts.length - 5) > 0">
				{{contacts.length - 5}} more account<span v-if="(contacts.length - 5) != 1">s</span>

				<div class="btn-grp">
					<a class="btn" @click="showAll = !showAll">
						Show All
					</a>
				</div>
			</div>
		</div>



	</div>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'contactFinder',
	components: {},
	props: ['contacts', 'account'],
	data: function() {
		return {
			search: '',
			showAll: false
		}
	},
	mounted: function() {
		var self = this;

		this.contacts.forEach(function(contact) {
			if (contact.selected) {
				self.$delete(contact, 'selected');
			}
		});
	},
	computed: {
		filteredContacts: function() {
			var self = this;
			return this.contacts.filter(function(contact) {
				var haystack = '';

				for (var key in contact) {
					if (typeof contact[key] == 'string') {
						haystack += contact[key].toLowerCase();
					}
				}

				if (haystack.indexOf(self.search.toLowerCase()) > -1) {
					return true;
				}

				return false;
			});
			

		}
	},
	methods: Object.assign(
		mapGetters({
			getAccounts: 'getAccounts'
		}),
		{
			handleSelect: function(contact) {
				var self = this;

				this.contacts.forEach(function(c) {
					if (c.selected) {
						c.selected = false;
					}
				});

				self.$set(contact, 'selected', true);

				this.$emit('select', {type: 'Contact', val: contact});
			}
		}
	)
}
</script>
