var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination" }, [
    _c(
      "a",
      {
        staticClass: "btn",
        on: {
          click: function ($event) {
            return _vm.page("first")
          },
        },
      },
      [_vm._v("First")]
    ),
    _vm._v(" "),
    _vm.here - 3 > 0
      ? _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.page(_vm.here - 3)
              },
            },
          },
          [_vm._v(_vm._s(_vm.here - 3))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.here - 2 > 0
      ? _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.page(_vm.here - 2)
              },
            },
          },
          [_vm._v(_vm._s(_vm.here - 2))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.here - 1 > 0
      ? _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.page(_vm.here - 1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.here - 1))]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("span", { staticClass: "btn btn-secondary" }, [
      _vm._v(_vm._s(_vm.here)),
    ]),
    _vm._v(" "),
    _vm.here + 1 < _vm.last
      ? _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.page(_vm.here + 1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.here + 1))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.here + 2 < _vm.last
      ? _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.page(_vm.here + 2)
              },
            },
          },
          [_vm._v(_vm._s(_vm.here + 2))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.here + 3 < _vm.last
      ? _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.page(_vm.here + 3)
              },
            },
          },
          [_vm._v(_vm._s(_vm.here + 3))]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "btn",
        on: {
          click: function ($event) {
            return _vm.page("last")
          },
        },
      },
      [_vm._v("Last")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }