var Attribute = {
	state: {
		attributeGroups: {
			1: [
				{ 'Bit Size': '3/32 inches' },
				{ 'Diameter': '2.40 mm' }
			],
			2: [
				{ 'Bit Size': '1 inch' },
				{ 'Diameter': '4.40 mm' },
				{ 'Shank Diameter': '0.5 inch' },
				{ 'Original Manufacturing Process': 'sintered' }
			]
		}
	},
	getters: {
		getAttributesById: (state) => (id) => {
			if (id) {
				return state.attributeGroups[id];
			}
		}
	}
}

export default Attribute;


