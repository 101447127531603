
var User = {
    state: {
        user: {}
        /*id: 17,
        name: 'Carl',
        email: 'carl@stryker.com',
        accountIDs: [1, 2, 3, 4],
        accounts: [{
            id: 1,
            name: 'St Joseph Beverly Hills',
            addressOne: '123 Main St',
            addressTwo: 'Bldg 7',
            addressThree: 'c/o Surgical',
            city: 'Beverly Hills',
            state: 'CA',
            zip: '90210'
        }]*/
    },
    getters: {
        getUser: (state) => {
            return state.user;
        },
        getIsLoggedIn: (state) => {
            return !!state.user.id;
        }
    },
    mutations: {
        setUser(state, data) {
            state.user = data;
        }
    },
    actions: {
        getUserId(context, arg) {
            return axios.get('/api/users/getauth');
        },
        getUserData(context, arg) {
            if (!context.state.user.id) {
                return;
            }

            // Note: this returns a resources out of 
            // Laravel, not a collection, like most do.
            axios.get('/api/users/getbyid?id=' + context.state.user.id)
                .then(function(resp) {
                    context.commit('setUser', resp.data.data);
                })
                .catch(function(error) {
                    console.log('user get error', error);
                })
        },
        loginUser(context, data) {
            return axios.post('/login', data);
        },
        logout(context, arg) {
            context.commit('setUser', {});
            return axios.post('/logout');
        }
    }
}

export default User;
