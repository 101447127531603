<template>
	<div>
		<div class="tab-nav">
			<button :class="tab == 'hub' ? 'tab active' : 'tab'"
				 @click="toggle('hub')">
				My Hub
			</button>
			<button :class="tab == 'accounts' ? 'tab active' : 'tab'"
				 @click="toggle('accounts')">
				My Accounts
			</button>
			<button :class="tab == 'orders' ? 'tab active' : 'tab'"
				 @click="toggle('orders')">
				My Label Orders
			</button>
			<button :class="tab == 'pdfs' ? 'tab active' : 'tab'"
				 @click="toggle('pdfs')">
				My Sales Sheets
			</button>

			<!-- "Feature Flag:" Hide (temporarily) for production deploy -->
			<button :class="tab == 'full-print' ? 'tab active' : 'tab'"
				 @click="toggle('full-print')" style="display: none;">
				Order Full Print Product Sheet
			</button>
		</div>

		<!-- <div>
			<p> {{historyProductIds.length}} </p>
		</div> -->

		<div class="tab-body">
			<div v-if="tab == 'hub'" class="tab-content" id="hub">
				<header class="subhead">
					<h2>My Account</h2>

					<a @click="logoutUser()"
						class="btn">
						Logout
					</a>
				</header>
				<p v-if="user.roles">
					User Type: <span>{{user.roles[0].name}}</span>
				</p>
				<p>
					First Name: <span>{{user.first_name}}</span>
				</p>
				<p>
					Last Name: <span>{{user.last_name}}</span>
				</p>
				<p>
					Email: <span>{{user.email}}</span>
				</p>
				<p>
					Phone: <span>{{user.phone}}</span>
				</p>

				<p  v-if="user.accounts">
					Active Accounts: <span>{{user.accounts.length}}</span>
				</p>
			</div>


			<div v-if="tab == 'accounts'" class="tab-content" id="accounts">
				<header class="subhead">
					<h2>
						Accounts
					</h2>

					<div>
						<router-link :to="{name: 'add-account'}"
							class="btn">
							Add New
						</router-link>
					</div>
				</header>


				<div class="accounts-grid" v-if="accounts.length">
					<div v-for="acc in accounts" class="account">
						<accountDetail class="account-card"
							:data-id="acc.id"
							:noclass="true"
							:account="acc"
							:order="false"
							:key="acc.id">
						</accountDetail>
					</div>
				</div>
				<div v-else>
					<p>No Accounts found.</p>
				</div>
			</div>


			<div v-if="tab == 'orders'" class="tab-content" id="orders">
				<div class="subhead">
					<h2>Label Order History</h2>
				</div>

				<div class="table-wrapper">
					<table>
						<thead>
							<tr>
								<th>Date</th>
								<th>Order Name</th>
								<th>Customer</th>
								<th class="min-175">Order Items</th>
								<th>Status</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							<orderRow v-if="labelHistory.length"
								v-for="order in labelHistory"
								@duplicate="handleDuplicate"
								:type="'label'"
								:key="order.id"
								:order="order">
							</orderRow>
						</tbody>
					</table>
				</div>
			</div>


			<div v-if="tab == 'pdfs'" class="tab-content" id="pdfs">
				<div class="subhead">
					<h2>Product Sheet Order History</h2>
				</div>

				<div class="table-wrapper">
					<table>
						<thead>
							<tr>
								<th>Date</th>
								<th>Order Name</th>
								<th>Customer</th>
								<th>Order Items</th>
								<th>Status</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							<orderRow v-if="pdfHistory.length"
								v-for="order in pdfHistory"
								@duplicate="handleDuplicate"
								:type="'product'"
								:key="order.id"
								:order="order">
							</orderRow>
						</tbody>
					</table>
				</div>
			</div>


			<div v-if="tab == 'full-print'" class="tab-content" id="full-print">
				<h2>
					Order Full Print Product Sheet
				</h2>

				<p>
					Place an order for the entire Stryker Cutting catalog.
				</p>

				<customerSelectAdd 
					ref="customerSelectAdd">
				</customerSelectAdd>

				<div>
					<h3>Alternative Stryker Parts</h3>

					<div class="">
						<p>
							<input type="checkbox" id="include" name="include" v-model="alternates_include" :value="alternates_include ? 'checked' : ''">
							<label for="include">Include alternate parts information in print catalog</label>
						</p>
					</div>

					<h3>Submit Order</h3>

					<div class="btn-group">
						<a v-on:click="full_print_order()" class="btn">Submit</a>
					</div>
				</div>



			</div>

		</div>
	</div>
</template>

<script>
import OrderRow from '../components/OrderRow.vue';
import AccountDetail from "../components/Account.vue";
import customerSelectAdd from "../components/CustomerSelectAdd.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'hub',
	data: function() {
		return {
			tab: 'hub',
			alternates_include: false
		}
	},
	components: {
		AccountDetail,
		OrderRow,
		customerSelectAdd
	},
	mounted: function() {
		if (!this.history.length) {
			this.fetchHistory();
		}

		if (!this.accounts.length) {
			this.fetchAccounts();
		}

		this.fetchProductById(this.historyProductIds)
	},
	computed: Object.assign(
		mapGetters({
			user: 'getUser',
			accounts: 'getAccounts',
			history: 'getHistory'
		}),
		{
			labelHistory: function() {
				return this.history.filter(order => (order.cart_type == 'label' && order.order_type == 'order'));
			},
			pdfHistory: function() {
				return this.history.filter(order => (order.cart_type == 'pdf' && order.order_type == 'order'));
			},
			historyProductIds: function() {
				var ids = [];
				this.history.forEach(function(order) {
					ids = ids.concat(order.order_products.map(product => product.product_id));
				});
				return ids;
			}
		}
	),
	methods: Object.assign(
		mapGetters({
			getAccounts: 'getAccounts',
		}),
		mapActions({
			fetchProductById: 'fetchProductById',
			fetchAccounts: 'fetchAccounts',
			fetchHistory: 'fetchHistory',
			logout: 'logout',
			postPrintOrder: 'postPrintOrder'
		}),
		{
			toggle(id) {
				this.tab = id;
			},
			logoutUser() {
				var self = this;

				this.logout()
					.then(response => {
                        // console.log('Logout successful X-CSRF-TOKEN', window.axios.defaults.headers.common['X-CSRF-TOKEN']);
						// console.log('Logout response', response);
                        window.axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrf_token;
		                self.$router.push({name: 'login'});
	                })
	                .catch(error => {
	                    console.log('Logout Error', error); // , error
	                })
	                .finally()
			},
			handleDuplicate(obj) {
				this.$emit('duplicate', obj);
			},
			reset: function(resp) {
				var message = 'Your Full Product Sheet Order was submitted successfully.';

				this.$emit('notify', message);

				this.$router.push({path: '/'});
			},
			full_print_order() {
				var self = this,
					account = this.$refs.customerSelectAdd.account,
					contact = this.$refs.customerSelectAdd.contact,
					valid = this.$validate('order', {...account, ...contact});

				if (valid.length) {
					return;
				}

				var form_data = {
					saveModeAccount: this.$refs.customerSelectAdd.saveModeAccount,
					saveModeContact: this.$refs.customerSelectAdd.saveModeContact,
					account: {
						...account,
						contact: contact
					},
					type: 'order',
					alternates_include: this.alternates_include
				}

				// console.log('Order:', form_data);

				this.postPrintOrder(form_data)
					.then(response => {
						// console.log('Sheet Resp', response);
						self.reset(response.data);
					})
					.catch(error => {
						console.log('Sheet Post Error', error);
					})
					.finally();
			}
		}
	)
}
</script>
