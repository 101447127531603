<template>
	<div class="filters">
		<div class="filters-header">
			<h2>Filters</h2>
			<div class="filters-actions">
				<div class="active-filters">
					<p>Active Filters</p>
					<ul class="btn-group filter-group">
						<li v-for="(filter, attr) in filterState"
							v-if="attr != 'category' && attr != 'search'"
							:data-attr="attr">
							<a class="btn btn-outline btn-small"
								@click="removeFilter(attr)">
								{{$niceAttr(attr)}}:
								<span v-if="filter.type == 'or'">
									{{filter.data.join(', ')}}
								</span>
								<span v-if="filter.type == 'in'">
									{{filter.data.join(' - ')}}
								</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<ul class="filters-ui">
			<li v-for="(filter, attr) in filters_meta"
				v-if="$niceAttr(attr) && 
					  filter.position == 'primary' && 
					  is_range(filter)"
				:data-inactive="filter.inactive || filter.min == filter.max">
				<filterRange :filter="filter"
					:id="attr"
					:key="keys[attr] + '-' + update"
					@rangeUpdate="rangeUpdate">
				</filterRange>
			</li>

			<li v-for="(filter, attr) in filters_meta"
				v-if="$niceAttr(attr) && 
					  filter.position == 'primary' && 
					  selectable(filter)"
				:data-inactive="filter.inactive">

				<div :class="'filter-group-container' + (keys[attr] ? ' active' : '')">
					<label :data-attr="attr">
						<!-- <input type="checkbox" :checked="!!keys[attr]"> -->
						{{$niceAttr(attr)}} 
						<span v-if="filter.products">({{filter.products}})</span>
					</label>

					<div class="filter-button-group btn-group">
						<a v-for="val in filter" 
							:class="'btn btn-outline' + (!!keys[attr] && activeOrFilterItems.includes(val) ? ' active' : '')"
							@click="toggleFilter({type: 'or', attribute: attr, data: val})">
							{{val}}
						</a>
					</div>
				</div>
			</li>
		</ul>

		<div class="more-filters" v-if="secondaryFilters.length">
			<button @click="moreFilters()" class="btn btn-primary">{{filters_toggle}} More Filters</button>
		</div>

		<div class="secondary-filters" :style="'height: ' + sf_height + ';'">
			<ul class="filters-ui" id="secondary-filters">
				<li v-for="(filter, attr) in filters_meta"
					v-if="$niceAttr(attr) && 
						  filter.position == 'secondary' && 
						  is_range(filter)"
					:data-inactive="filter.inactive || filter.min == filter.max">
					<filterRange :filter="filter"
						:id="attr"
						:key="keys[attr] + '-' + update"
						@rangeUpdate="rangeUpdate">
					</filterRange>
				</li>

				<li v-for="(filter, attr) in filters_meta"
					v-if="$niceAttr(attr) && 
						  filter.position == 'secondary' && 
						  selectable(filter)"
					:data-inactive="filter.inactive">

					<div :class="'filter-group-container' + (keys[attr] ? ' active' : '')">
						<label :data-attr="attr">
							<!-- <input type="checkbox" :checked="!!keys[attr]"> -->
							{{$niceAttr(attr)}} 
							<span v-if="filter.products">({{filter.products}})</span>
						</label>

						<div class="filter-button-group btn-group">
							<a v-for="val in filter" 
								:class="'btn btn-outline' + (!!keys[attr] && activeOrFilterItems.includes(val) ? ' active' : '')"
								@click="toggleFilter({type: 'or', attribute: attr, data: val})">
								{{val}}
							</a>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>

import filterRange from "../components/FilterRange.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'filters',
	props: ['filters_meta'],
	components: {
		filterRange
	},
	data: function() {
		return {
			// filterState: {}
			keys: {},
			ready_filters: {},
			filters_toggle: 'Show',
			sf_height: 0,
			update: 0
		}
	},
	mounted: function() {
		var self = this;

		// console.log('filters at mounted', this.filters_meta);

		for (var filter in this.filters_meta) {
			this.$set(this.keys, filter, false);
		}

		// TODO: Is chrome/safari. 
		// 
		// For some reason, Chrome (and Safari, too, I expect)
		// chokes on correctly setting the slider start positions
		// that the JS uses to calculate how much a slider has 
		// moved. I think it's because the DOM blinks when styles
		// are loading. This... seems to reliably wait until things
		// are settled to cause Vue to build the Filters UI.
		// 
		// TODO: find out about Edge, too.
		// 
		if (true) { 
			setTimeout(function() {
				self.ready_filters = self.filters_meta;
			}, 100);
		// 
		// Firefox, of course, has no issues whatsoever.
		// 
		} else {
			self.ready_filters = self.filters_meta;
		}
	},
	computed: {
		filterState: function() {
			return this.getFilters();
		},
		secondaryFilters: function() {
			return Object.values(this.filters_meta)
				.map(fl => fl.position)
				.filter(pos => pos == 'secondary');
		},
		activeOrFilterItems: function() {
			var filters = this.getFilters();

			var activeGroups = [];

			for (var attr in filters) {
				if (filters[attr].data && filters[attr].type == 'or') {
					filters[attr].data.forEach(function(str) {
						activeGroups.push(str);
					});
				}
			}

			return activeGroups;
		}
	},
	methods: Object.assign(
		mapActions({
			fetchProducts: 'fetchProducts',
			mergeFilters: 'mergeFilters',
			removeFilters: 'removeFilters',
			removeInFilters: 'removeInFilters'
		}),
		mapGetters({
			getFilters: 'getFilters',
		}),
		{
			toggleFilter: function(arg) {
				var current = this.filterState[arg.attribute] || {type: arg.type, data: []};

				if (!current.data.includes(arg.data)) {
					current.data.push(arg.data);
				} else {
					if (arg.type == 'or' && current.data.length > 1) {
						var splice = {};
						splice[arg.attribute] = arg.data;

						this.removeInFilters(splice);
					} else {
						this.removeFilter(arg.attribute);
					}
					return;
				}

				var filters = {};

				filters[arg.attribute] = {type: arg.type, data: current.data};

				this.mergeFilters(filters);
				this.keys[arg.attribute] = true;

				this.$emit('select', 'none');
				this.fetchProducts();
			},
			rangeUpdate: function(attr) {
				this.keys[attr] = true;

				this.$emit('select', 'none');
			},
			// removeAttr: function(obj) {
			// 	this.removeInFilters(obj);
			// 	this.keys[remove] = false;
			// },
			removeFilter: function(remove) {
				this.removeFilters(remove);

				this.keys[remove] = false;

				this.$emit('select', 'none');
				this.fetchProducts();

				// Send signal to child components to
				// force them to look for updates
				// we've made to the state.
				this.update++;
			},
			is_range: function(fltr) {
				if (Array.isArray(fltr)) {
					return false;
				} 

				return true;
			},
			selectable: function(fltr) {
				if (Array.isArray(fltr)) {
					return true;
				}
				return false;
			},
			moreFilters: function() {
				var h = document.getElementById('secondary-filters').offsetHeight;

				this.filters_toggle = this.filters_toggle == 'Hide' ? 'Show' : 'Hide';
				this.sf_height = this.sf_height == 0 ? (h + 'px') : 0;
			}
		}
	)
}
</script>