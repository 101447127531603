var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-page" }, [
    _c("div", { staticClass: "subhead" }, [
      _c("h2", [_vm._v("Product Sheet PDF")]),
      _vm._v(" "),
      _vm.sheet.length
        ? _c(
            "div",
            [
              _c(
                "router-link",
                {
                  staticClass: "btn",
                  attrs: { to: { name: "sales-sheet-preview" } },
                },
                [_vm._v("\n\t\t\t\tPreview\n\t\t\t")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "subhead" }, [
      _c("h3", [_vm._v("Products")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _c("label", [_vm._v("PDF Name (optional)")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name",
            },
          ],
          attrs: { type: "text", name: "sheet-name" },
          domProps: { value: _vm.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-wrapper carts pdf-cart" }, [
      _vm.sheet.length
        ? _c("table", [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.sheet, function (product, ind) {
                return _c(
                  "tr",
                  {
                    class:
                      _vm.editing[ind] || product.note.text ? "has-extra" : "",
                  },
                  [
                    _c("td", [
                      product.image
                        ? _c("img", { attrs: { src: product.image } })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { path: "/product/" + product.id } } },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(product.name) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(product.description))]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.noteAddEdit(ind, product.id)
                            },
                          },
                        },
                        [
                          !product.note.text
                            ? _c("span", [_vm._v("Add")])
                            : _c("span", [_vm._v("Edit")]),
                          _vm._v(" Note\n\t\t\t\t\t\t"),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.remove(product.id)
                            },
                          },
                        },
                        [_vm._v("Remove")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.editing[ind]
                      ? _c("div", { staticClass: "row-extra" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: product.note.text,
                                expression: "product.note.text",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: product.note.text },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  product.note,
                                  "text",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              on: {
                                click: function ($event) {
                                  product.note.text = ""
                                  _vm.$set(_vm.editing, ind, false)
                                },
                              },
                            },
                            [
                              !product.note.text
                                ? _c("span", [_vm._v("×")])
                                : _c("span", [_vm._v("Clear")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              on: {
                                click: function ($event) {
                                  return _vm.noteSave(ind, product.id)
                                },
                              },
                            },
                            [_vm._v("Save")]
                          ),
                        ])
                      : product.note.text
                      ? _c("div", { staticClass: "row-extra" }, [
                          _c("p", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(product.note.text) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                            _c("small", [
                              _vm._v(
                                "(Added: " +
                                  _vm._s(_vm.$date(product.note.created)) +
                                  ")"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.sheet.length
      ? _c("div", { staticClass: "cart-actions" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.clear()
                },
              },
            },
            [_vm._v("Clear Cart")]
          ),
        ])
      : _c("div", [
          _c("p", [
            _vm._v("Products added to your PDF cart will display here."),
          ]),
        ]),
    _vm._v(" "),
    _vm.sheet.length
      ? _c(
          "div",
          [
            _c("h2", [_vm._v("Customer Information")]),
            _vm._v(" "),
            _c("customerSelectAdd", {
              key: _vm.uuid,
              ref: "customerSelectAdd",
            }),
            _vm._v(" "),
            _c("div", [
              _c("h3", [_vm._v("Generate and Send PDF")]),
              _vm._v(" "),
              _c("div", { staticClass: "btn-group" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.submit()
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Thumbnail")]),
        _vm._v(" "),
        _c("th", [_vm._v("Product")]),
        _vm._v(" "),
        _c("th", [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }