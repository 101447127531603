/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

window.$uuid = function() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

import Helpers from './helpers.js';

import router from './router';
import App from './layouts/App.vue';
import Vuex from "vuex";
import _ from "lodash";

// import { ZiggyVue } from 'ziggy';
// import { Ziggy } from './ziggy';
// import route from "ziggy-js";


// console.log('Token?', token);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.use(Helpers);
Vue.use(Vuex);
// Vue.use(ZiggyVue, Ziggy);

// Vue.mixin({
//     methods: {
//         route: (name, params, absolute) => route(name, params, absolute, Ziggy),
//     },
// });

// separate stores
import Product from "./models/Product.js";
import Category from "./models/Category.js";
import Attribute from "./models/Attribute.js";
import History from "./models/History.js";
import Cart from "./models/Cart.js";
import Sheet from "./models/Sheet.js";
import Account from "./models/Account.js";
import Contact from "./models/Contact.js";
import User from "./models/User.js";

const vuex_store = new Vuex.Store({
    state: Object.assign(
        Product.state,
        Category.state,
        Attribute.state,
        History.state,
        Cart.state,
        Sheet.state,
        Account.state,
        Contact.state,
        User.state
    ),
    getters: Object.assign(
        Product.getters,
        Category.getters,
        Attribute.getters,
        History.getters,
        Cart.getters,
        Sheet.getters,
        Account.getters,
        Contact.getters,
        User.getters,
        {
            getTestData(state) {
                return state.testData;
            }
        },
    ),
    mutations: Object.assign(
        Cart.mutations,
        Sheet.mutations,
        Product.mutations,
        Category.mutations,
        History.mutations,
        Account.mutations,
        User.mutations,
        Contact.mutations,
        {}
    ),
    actions: Object.assign(
        Product.actions,
        Category.actions,
        Sheet.actions,
        Cart.actions,
        History.actions,
        Account.actions,
        User.actions,
        Contact.actions,
        {
            initialize (context) {

            },
            save (context, data) {
                // Runs on update Vue events
                localStorage.setItem(data.cart, JSON.stringify(data));
            },
            fetch (context, data) {
                // Called in mounted of App.vue per data.config.storage
                var ret = {
                    products: [],
                    name: '',
                    uuid: ''
                };

                try {
                    ret = JSON.parse(localStorage.getItem(data.cart));
                } catch (error) {
                    console.log('Corrupt data error in JSON parse', error);
                }

                if (ret) {
                    context.commit('set' + data.model, ret.products);
                    context.commit('set' + data.model + 'Name', ret.name);
                    context.commit('set' + data.model + 'Uuid', ret.uuid);
                }
            }
        }
    )
});

// Auth enforcement.
router.beforeEach((to, from, next) => {
    if (
        // Disable for development.
        // false &&
        to.name !== 'login' && !vuex_store.state.user.id
    ) {
        vuex_store._actions.getUserId[0]().then(function(response) {
            if (response.data.id) {
                vuex_store.state.user = response.data;

                vuex_store._actions.getUserData[0]();
                next();

            } else {
                if (from.name != 'login') {
                    next({name: 'login'});
                }
            }
        })
        .catch(function(error) {
            router.push({name: 'login'})
                .catch(error => {
                    console.log('Rerouting error after routing error', error);
                });
        });
    } else {
        next();
    }
});


window.axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        vuex_store.state.user = {};

        document.getElementById('loading-lock').classList.remove('is-loading');

        router.push({name: 'login'})
            .catch(error => {
                console.log('Rerouting error after routing error', error);
            });
    } else {
        return Promise.reject(error);
    }
});

// window.axios.interceptors.response.use(
//     response => {
//         return response;
//     },
//     error => {
//         if (error.response.status === 401) {
//             // console.log('401 RESPONSE DETECTED!');
//
//             vuex_store.state.user = {};
//
//             document.getElementById('loading-lock').classList.remove('is-loading');
//
//             router.push({name: 'login'})
//                 .catch(error => {
//                     console.log('Rerouting error after routing error', error);
//                 });
//         }
//         return error;
//     }
// );

vuex_store.dispatch("initialize");

const app = new Vue({
    router,
    el: '#app',
    store: vuex_store,
    data: {
        sheet: []
    },
    methods: {

    },
    render: h => h(App)
});
