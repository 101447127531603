var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accounts" }, [
    _c("header", { staticClass: "subhead" }, [
      _c("h3", [_vm._v("\n\t\t\tMy Accounts\n\t\t")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _c("label", [_vm._v("Search")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search",
            },
          ],
          attrs: { type: "text", name: "search" },
          domProps: { value: _vm.search },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.search = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _vm.search && _vm.filteredAccounts.length
      ? _c(
          "div",
          { staticClass: "accounts-grid" },
          _vm._l(_vm.filteredAccounts.slice(0, 6), function (account) {
            return _c("account", {
              key: "f-" + account.id,
              staticClass: "account-card filtered",
              attrs: {
                account: account,
                order: false,
                "data-selected": account.selected,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleSelect(account.id)
                },
              },
            })
          }),
          1
        )
      : _vm.search && !_vm.filteredAccounts.length
      ? _c("div", { staticClass: "accounts-grid" }, [
          _c("div", { staticClass: "account" }, [
            _vm._v(
              "\n\t\t\tNo results in " +
                _vm._s(_vm.accounts.length) +
                " account"
            ),
            _vm.accounts.length != 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
            _vm._v(".\n\t\t"),
          ]),
        ])
      : _vm.showAll
      ? _c(
          "div",
          { staticClass: "accounts-grid" },
          [
            _vm._l(_vm.accounts, function (account) {
              return _c("account", {
                key: account.id,
                staticClass: "account-card",
                attrs: {
                  account: account,
                  order: false,
                  "data-selected": account.selected,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleSelect(account.id)
                  },
                },
              })
            }),
            _vm._v(" "),
            _c("div", { staticClass: "account" }, [
              _vm._v(
                "\n\t\t\tShowing " +
                  _vm._s(_vm.accounts.length - 5) +
                  " additional account"
              ),
              _vm.accounts.length - 5 != 1
                ? _c("span", [_vm._v("s")])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "btn-grp" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        _vm.showAll = !_vm.showAll
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\tHide\n\t\t\t\t")]
                ),
              ]),
            ]),
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "accounts-grid" },
          [
            _vm._l(_vm.accounts.slice(0, 5), function (account) {
              return _c("account", {
                key: account.id,
                staticClass: "account-card",
                attrs: {
                  account: account,
                  order: false,
                  "data-selected": account.selected,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleSelect(account.id)
                  },
                },
              })
            }),
            _vm._v(" "),
            _vm.accounts.length - 5 > 0
              ? _c("div", { staticClass: "account" }, [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.accounts.length - 5) +
                      " more account"
                  ),
                  _vm.accounts.length - 5 != 1
                    ? _c("span", [_vm._v("s")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn-grp" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn",
                        on: {
                          click: function ($event) {
                            _vm.showAll = !_vm.showAll
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\tShow All\n\t\t\t\t")]
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }