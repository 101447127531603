<template>
	<div>
		<accountFinder class="account-finder"
			@select="select">
		</accountFinder>


		<div class="label-order-destination form">
			<div class="input-group" data-validate="name">
				<label>Organization Name</label>
				<input type="text" v-model="account.name">
			</div>

			<div class="input-group closer" data-validate="addressOne">
				<label>Address One</label>
				<input type="text" v-model="account.addressOne">
			</div>
			<div class="input-group closer">
				<label>Address Two</label>
				<input type="text" v-model="account.addressTwo">
			</div>
			<div class="input-group closer">
				<label>Address Three</label>
				<input type="text" v-model="account.addressThree">
			</div>
			<div class="input-group closer" data-validate="city">
				<label>City</label>
				<input type="text" v-model="account.city">
			</div>
			<div class="input-group closer" data-validate="state">
				<label>State</label>
				<input type="text" v-model="account.state">
			</div>
			<div class="input-group" data-validate="zip">
				<label>Zip Code</label>
				<input type="text" v-model="account.zip">
			</div>

			<div class="input-group radio closer"
				v-if="!Object.keys(selected.account).length ||
					(Object.keys(selected.account).length && changedAccount)">
				<label>Save as new account</label>
				<input type="radio" v-model="saveModeAccount" value="add">
			</div>
			<div class="input-group radio closer"
				v-if="Object.keys(selected.account).length && changedAccount">
				<label>Update current account</label>
				<input type="radio" v-model="saveModeAccount" value="update">
			</div>
			<div class="input-group radio"
				v-if="!Object.keys(selected.account).length ||
					(Object.keys(selected.account).length && changedAccount)">
				<label>Do not save Account</label>
				<input type="radio" v-model="saveModeAccount" value="abandon">
			</div>

			<p v-if="!Object.keys(selected.account).length ||
				(Object.keys(selected.account).length && changedAccount) ||
				Object.keys(selected.account).length && changedAccount">
				Changes will be saved when this order is submitted.
			</p>
		</div>


		<contactFinder class="contact-finder"
			v-if="Object.keys(selected.account).length"
			:account="selected.account"
			:contacts="selected.account.contacts"
			@select="select">
		</contactFinder>


		<div class="sheet-order-contact form">
			<div class="input-group closer" data-validate="first_name">
				<label>First Name</label>
				<input type="text" v-model="contact.first_name">
			</div>
			<div class="input-group closer" data-validate="last_name">
				<label>Last Name</label>
				<input type="text" v-model="contact.last_name">
			</div>

			<div class="input-group" data-validate="email">
				<label>Email Address</label>
				<input type="text" v-model="contact.email">
			</div>
			<div class="input-group">
				<label>Title</label>
				<input type="text" v-model="contact.title">
			</div>

			<div class="input-group radio closer"
				v-if="canSaveContact">
				<label>Add new contact to
					<span v-if="selected.account.name">{{selected.account.name}}</span>
					<span v-else-if="account.name">new account ({{account.name}})</span>
				</label>
				<input type="radio" v-model="saveModeContact" value="add">
			</div>
			<div class="input-group radio closer"
				v-if="canSaveContact && changedContact && Object.keys(selected.contact).length">
				<label>Update this contact</label>
				<input type="radio" v-model="saveModeContact" value="update">
			</div>
			<div class="input-group radio"
				v-if="canSaveContact && changedContact">
				<label>Do not save this contact</label>
				<input type="radio" v-model="saveModeContact" value="abandon">
			</div>
		</div>
	</div>
</template>

<script>
import AccountFinder from "../components/Finders/Account.vue";
import ContactFinder from "../components/Finders/Contact.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'customerSelectAdd',
	data: function() {
		return {
			account: {},
			contact: {},
			selected: {
				account: {},
				contact: {}
			},
			saveModeAccount: 'add',
			saveModeContact: 'add'
		}
	},
	components: { AccountFinder, ContactFinder },
	mounted: function() {
		var self = this;

        // console.log(this.account);
	},
	computed: Object.assign(
		mapGetters({}),
		{
			canSaveContact() {
				if (
					(this.saveModeAccount === 'abandon' && !Object.keys(this.selected.account).length) ||
					(Object.keys(this.selected.contact).length && !this.changedContact)
				) {
					return false;
				}

				return true;
			},

			changedAccount() {
				var change = false;
				for (let key in this.selected.account) {
					if (this.account[key] != this.selected.account[key]) {
						change = true;
					}
				}
				return change;
			},
			changedContact() {
				var change = false;
				for (let key in this.selected.contact) {
					if (this.contact[key] != this.selected.contact[key]) {
						change = true;
					}
				}
				return change;
			}
		}
	),
	methods: {
		select: function(selected) {
			var type = selected.type,
				model = selected.type.toLowerCase(),
				val = selected.val;

			this['saveMode' + type] = 'update';

			if (this.selected[model].id == val.id) {
				this[model] = {};
				this['saveMode' + type] = 'add';

				if (type == 'Account') {
					this.contact = {};
				}

				this.selected[model] = {};

				return;
			}

			this[model] = this.$clone(val);
			this.selected[model] = val;

		}
	}
}
</script>


