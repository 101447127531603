var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _c("div", { staticClass: "filters-header" }, [
      _c("h2", [_vm._v("Filters")]),
      _vm._v(" "),
      _c("div", { staticClass: "filters-actions" }, [
        _c("div", { staticClass: "active-filters" }, [
          _c("p", [_vm._v("Active Filters")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "btn-group filter-group" },
            _vm._l(_vm.filterState, function (filter, attr) {
              return attr != "category" && attr != "search"
                ? _c("li", { attrs: { "data-attr": attr } }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-outline btn-small",
                        on: {
                          click: function ($event) {
                            return _vm.removeFilter(attr)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.$niceAttr(attr)) +
                            ":\n\t\t\t\t\t\t\t"
                        ),
                        filter.type == "or"
                          ? _c("span", [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(filter.data.join(", ")) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        filter.type == "in"
                          ? _c("span", [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(filter.data.join(" - ")) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e()
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "filters-ui" },
      [
        _vm._l(_vm.filters_meta, function (filter, attr) {
          return _vm.$niceAttr(attr) &&
            filter.position == "primary" &&
            _vm.is_range(filter)
            ? _c(
                "li",
                {
                  attrs: {
                    "data-inactive":
                      filter.inactive || filter.min == filter.max,
                  },
                },
                [
                  _c("filterRange", {
                    key: _vm.keys[attr] + "-" + _vm.update,
                    attrs: { filter: filter, id: attr },
                    on: { rangeUpdate: _vm.rangeUpdate },
                  }),
                ],
                1
              )
            : _vm._e()
        }),
        _vm._v(" "),
        _vm._l(_vm.filters_meta, function (filter, attr) {
          return _vm.$niceAttr(attr) &&
            filter.position == "primary" &&
            _vm.selectable(filter)
            ? _c("li", { attrs: { "data-inactive": filter.inactive } }, [
                _c(
                  "div",
                  {
                    class:
                      "filter-group-container" +
                      (_vm.keys[attr] ? " active" : ""),
                  },
                  [
                    _c("label", { attrs: { "data-attr": attr } }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.$niceAttr(attr)) +
                          " \n\t\t\t\t\t"
                      ),
                      filter.products
                        ? _c("span", [
                            _vm._v("(" + _vm._s(filter.products) + ")"),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "filter-button-group btn-group" },
                      _vm._l(filter, function (val) {
                        return _c(
                          "a",
                          {
                            class:
                              "btn btn-outline" +
                              (!!_vm.keys[attr] &&
                              _vm.activeOrFilterItems.includes(val)
                                ? " active"
                                : ""),
                            on: {
                              click: function ($event) {
                                return _vm.toggleFilter({
                                  type: "or",
                                  attribute: attr,
                                  data: val,
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" + _vm._s(val) + "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ])
            : _vm._e()
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.secondaryFilters.length
      ? _c("div", { staticClass: "more-filters" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              on: {
                click: function ($event) {
                  return _vm.moreFilters()
                },
              },
            },
            [_vm._v(_vm._s(_vm.filters_toggle) + " More Filters")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "secondary-filters",
        style: "height: " + _vm.sf_height + ";",
      },
      [
        _c(
          "ul",
          { staticClass: "filters-ui", attrs: { id: "secondary-filters" } },
          [
            _vm._l(_vm.filters_meta, function (filter, attr) {
              return _vm.$niceAttr(attr) &&
                filter.position == "secondary" &&
                _vm.is_range(filter)
                ? _c(
                    "li",
                    {
                      attrs: {
                        "data-inactive":
                          filter.inactive || filter.min == filter.max,
                      },
                    },
                    [
                      _c("filterRange", {
                        key: _vm.keys[attr] + "-" + _vm.update,
                        attrs: { filter: filter, id: attr },
                        on: { rangeUpdate: _vm.rangeUpdate },
                      }),
                    ],
                    1
                  )
                : _vm._e()
            }),
            _vm._v(" "),
            _vm._l(_vm.filters_meta, function (filter, attr) {
              return _vm.$niceAttr(attr) &&
                filter.position == "secondary" &&
                _vm.selectable(filter)
                ? _c("li", { attrs: { "data-inactive": filter.inactive } }, [
                    _c(
                      "div",
                      {
                        class:
                          "filter-group-container" +
                          (_vm.keys[attr] ? " active" : ""),
                      },
                      [
                        _c("label", { attrs: { "data-attr": attr } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.$niceAttr(attr)) +
                              " \n\t\t\t\t\t\t"
                          ),
                          filter.products
                            ? _c("span", [
                                _vm._v("(" + _vm._s(filter.products) + ")"),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "filter-button-group btn-group" },
                          _vm._l(filter, function (val) {
                            return _c(
                              "a",
                              {
                                class:
                                  "btn btn-outline" +
                                  (!!_vm.keys[attr] &&
                                  _vm.activeOrFilterItems.includes(val)
                                    ? " active"
                                    : ""),
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleFilter({
                                      type: "or",
                                      attribute: attr,
                                      data: val,
                                    })
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(val) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ])
                : _vm._e()
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }