<template>
	<div class="about-page">
		<h2>How to Use</h2>


		<div class="video-container">
			<h3>Full Walkthrough</h3>
			<video controls poster="images/walkthrough.png"> <!-- width="320" height="240" -->
				<source src="video/Cutting Accessories - Full Walkthrough v2-Broadband Low-1.mp4" type="video/mp4">
			</video>
		</div>

		<div class="video-container">
			<h3>Topic: Product Search</h3>
			<video controls poster="images/product-search.png">
				<!-- <source src="video/Cutting Accessories - 1 Product Search-Broadband Low-1.mp4" type="video/mp4"> -->
				<source src="video/search-functionality.mp4" type="video/mp4">
			</video>
		</div>

		<div class="video-container">
			<h3>Topic: Category Filtering</h3>
			<video controls poster="images/category-filtering.png">
				<source src="video/Cutting Accessories - 2 Category Filtering-Broadband Low-1.mp4" type="video/mp4">
			</video>
		</div>

		<div class="video-container">
			<h3>Topic: Product Sheets</h3>
			<video controls poster="images/product-sheets.png">
				<!-- <source src="video/Cutting Accessories - 3 Product Sheets-Broadband Low-1.mp4" type="video/mp4"> -->
				<source src="video/product-sheets.mp4" type="video/mp4">
			</video>
		</div>

		<div class="video-container">
			<h3>Topic: Label Ordering</h3>
			<video controls poster="images/label-ordering.png">
				<!-- <source src="video/Cutting Accessories - 4 Label Ordering-Broadband Low-1.mp4" type="video/mp4"> -->
				<source src="video/blade-labels.mp4" type="video/mp4">
			</video>
		</div>

		<div class="video-container">
			<h3>Topic: My Hub</h3>
			<video controls poster="images/my-hub.png">
				<source src="video/Cutting Accessories - 5 My Hub-Broadband Low-1.mp4" type="video/mp4">
			</video>
		</div>


	</div>
</template>

<script>
export default {
	components: {
	}
}
</script>
