
var Product = {
    state: {
        filters: {},
        filters_meta: {},
        category: [],
        pagination: {
            current: 1,
            last_page: null,
            total: null,
            first: '/api/products?page=1',
            next: '/api/products?page=2',
            last: null,
            prev: null
        },
        products: [],
        partnumbers: [],
        exclude: [
            'id',
            'product_id',
            'created_at',
            'updated_at'
        ],
        primaries: {
            7: [
                'attachment_type',
                'head_diameter',
                'total_length',
                'head_length',
                'number_of_flutes',
                'grit'
            ],
            default: [
                'attachment_type',
                'blade_type',
                'thickness',
                'cut_edge',
                'cut_depth',
                'replacement',
                'compatible'
            ]
        }

    },
    getters: {
        getProductsByCategoryId: (state) => (id) => {
            if (id) {
                return state.products.filter(product => product.group_id == id);

                // return state.products.filter(product => state.productCategories.map(function (prcat) {
                //     return (prcat.categoryId == id) ? prcat.productId : false;
                // }).includes(product.id))
            }

            return false;
        },
        getPagination(state) {
            return state.pagination;
        },
        getProducts(state) {
            return state.products;
        },
        getPartNumbers(state) {
            return state.partnumbers;
        },
        getFilters(state) {
            return state.filters;
        },
        getFiltersMeta(state) {
            return state.filters_meta;
        },
        getProductsById: (state) => (id) => {
            return state.products.filter(product => product.id == id);
        },

        // All of these will probably need to be replaced with
        // just references to some Laravel package or something.
        //
        searchPartNumbers: (state) => (term) => {
            return state.products.filter(item => item.part_number.includes(term));
        },
        searchNames: (state) => (term) => {
            return state.products.filter(item => item.name.toLowerCase().includes(term));
        },
        searchKeyword: (state) => (term) => {
            // state.dispatch('mergeFilters', {'search': term});
            // state.dispatch('fetchProducts', 1);

            return state.products.filter(function(product) {
                var text_blob = '';

                text_blob += product.name + ' ' + product.part_number + ' ' + product.description;
                text_blob += ''; // Attribute values.

                if (text_blob.toLowerCase().includes(term)) {
                    var i = text_blob.indexOf(term),
                    s = (i > 10 ? i - 10 : i),
                    e = (i + term.length + 10),
                    t = text_blob.substring(
                        s,
                        e < text_blob.length ? e : text_blob.length
                    );

                    t = (i >= 10 ? '...' : '') + t;
                    t += e == text_blob.length ? '' : '...';

                    product.keyword = t;
                    return true;
                }
            })
        }
    },
    mutations: {
        loadProducts (state, payload) {
            // for (var i = 0; i < 6; i++) {
            //     console.log('products response:', JSON.stringify(payload[i]));
            // }

            state.products = payload;
        },
        loadPartNumbers (state, payload) {
            state.partnumbers = payload;
        },
        paginate (state, payload) {
            // Either a bug, or this doesn't come back when collection
            // is less than one page.
            // console.log('Has meta', payload.meta);
            if (payload.meta && payload.links) {
                state.pagination = payload.links;
                state.pagination.current = payload.meta.current_page;
                state.pagination.last_page = payload.meta.last_page;
                state.pagination.total = payload.meta.total;
            } else {
                state.pagination.current = 1;
                state.pagination.last_page = 1;
                state.pagination.total = 1;
            }
        },
        filters (state, payload) {
            state.filters = payload;
        },
        setFiltersMeta(state, filters_meta) {
            var data = {};

            for (var filter in filters_meta) {
                if (!state.exclude.includes(filter)) {
                    data[filter] = filters_meta[filter];
                    data[filter].range = '';

                    if (state.primaries[state.filters.category]) {
                        data[filter].position = state.primaries[state.filters.category].includes(filter) ? 'primary' : 'secondary';
                    } else {
                        data[filter].position = state.primaries.default.includes(filter) ? 'primary' : 'secondary';
                    }
                }
            }

            // TODO Sort filters

            state.filters_meta = data;
        },

        updateFiltersMeta(state, resp_meta) {
            var updatedFilters = {};

            for (var filter in state.filters_meta) {
                if (!resp_meta[filter]) {
                    updatedFilters[filter] = state.filters_meta[filter];
                    updatedFilters[filter].products = '0';
                    updatedFilters[filter].inactive = true;
                    updatedFilters[filter].range = '';
                } else if (state.filters[filter]) {
                    var total = state.filters_meta[filter].products || false;
                    var affected = resp_meta[filter].products || false;

                    updatedFilters[filter] = state.filters_meta[filter];

                    // TODO: this ends up not being reactive.
                    if (total && affected) {
                        updatedFilters[filter].range = affected + ' of ' + total;
                    }
                } else {
                    updatedFilters[filter] = resp_meta[filter];
                    updatedFilters[filter].range = '';
                }

                if (state.primaries[state.filters.category]) {
                    updatedFilters[filter].position = state.primaries[state.filters.category].includes(filter) ? 'primary' : 'secondary';
                } else {
                    updatedFilters[filter].position = state.primaries.default.includes(filter) ? 'primary' : 'secondary';
                }
            }

            state.filters_meta = updatedFilters;
        }
    },
    actions: {
        // fetch products?

        mergeFilters(context, obj) {
            if (!Object.keys(obj).length) {
                context.commit('filters', {});
            }

            let filters = {
                ...context.state.filters,
                ...obj
            }

            // console.log('merged filters', filters);

            context.commit('filters', filters);
        },
        removeFilters(context, remove) {
            let current = context.state.filters;

            delete current[remove];

            context.commit('filters', current);
        },
        removeInFilters(context, remove) {
            let current = context.state.filters;

            for (var attr in remove) {
                current[attr].data.splice(current[attr]['data'].indexOf(remove[attr]), 1);
            }

            context.commit('filters', current);
        },
        resetFilters(context, obj) {
            context.commit('filters', {});
        },
        fetchProductById(context, id) {
            document.getElementById('loading-lock').classList.add('is-loading');

            var tar = '/api/products';

            if (id && Array.isArray(id)) {
                tar += '?ids=' + JSON.stringify(id);
            } else {
                tar += '?id=' + id;
            }

            // console.log('fetch by id arg', tar);

            return axios.get(tar)
                .then(response => {
                    var resp = response.data.data;
                    // var filters_meta = response.data.data.filters;

                    // console.log('fetch by id resp', response);

                    var products = [];
                    var partnumbers = [];

                    for (var ind in resp) {
                        if (ind != 'filters') {
                            products.push(resp[ind]);
                        }
                    }

                    // Adjust columns values for presentation
                    products.forEach(function(product) {
                        // product.description = product.name;
                        product.name = product.part_number;
                        product.name += product.special ? ' ' + product.special : '';
                        product.image = '/storage/products/' + product.image;

                        partnumbers.push({id: product.id, pn: product.name});
                    });

                    context.commit('loadProducts', products);

                    context.commit('loadPartNumbers', partnumbers);

                    document.getElementById('loading-lock').classList.remove('is-loading');
                })
                .catch(error => {
                    console.log('Fetch Products Error', error);
                });
        },
        fetchProducts(context, arg) { // { commit, state, getters }) {
            document.getElementById('loading-lock').classList.add('is-loading');

            var tar = '/api/products';

            if (typeof arg == 'number') {
                tar += arg ? '?page=' + arg : '';
            } else if (typeof arg == 'string') {
                tar = context.state.pagination[arg];
            } else {
                tar += '?page=1';
            }

            // console.log('Filter state:', context.state.filters);

            var fetchFilters = [];

            if (Object.keys(context.state.filters).length) {
                for (var param in context.state.filters) {
                    var obj = context.state.filters[param];
                    switch (param) {
                        case 'search':
                            tar += '&search=' + obj;
                            break;
                        case 'category':
                            tar += '&category=' + obj;
                            break;
                        default:
                            obj.attr = param;
                            fetchFilters.push(obj);
                            break;
                    }
                }

                if (fetchFilters.length) {
                    tar += '&filterArg=' + JSON.stringify(fetchFilters);
                }
            }

            //console.log('target:', tar);

            axios.get(tar)
                .then(response => {
                    var resp = response.data.data;
                    var filters_meta = response.data.data.filters;

                    //console.log('resp', response);

                    var products = [];
                    var partnumbers = [];

                    for (var ind in resp) {
                        if (ind != 'filters') {
                            products.push(resp[ind]);
                        }
                    }

                    // Adjust columns values for presentation
                    products.forEach(function(product) {
                        // product.description = product.name;
                        product.name = product.part_number;
                        product.name += product.special ? ' ' + product.special : '';
                        product.image = '/storage/products/' + product.image;

                        partnumbers.push({id: product.id, pn: product.name});
                    });

                    // console.log('meta resp', filters_meta);

                    context.commit('loadProducts', products);

                    context.commit('loadPartNumbers', partnumbers);

                    if (Object.keys(context.state.filters_meta).length) {
                        context.commit('updateFiltersMeta', filters_meta);
                    } else {
                        context.commit('setFiltersMeta', filters_meta);
                    }
                    // context.commit('paginate', response.data);

                    // console.log(context.state.filters_meta);

                    document.getElementById('loading-lock').classList.remove('is-loading');
                })
                .catch(error => {
                    console.log('Fetch Products Error', error);
                });
        }
    }
}

export default Product;
