<template>
	<div class="cart-page">
		<div class="subhead">
			<h2>Product Sheet PDF</h2>

			<div v-if="sheet.length">
				<router-link class="btn"
					:to="{name: 'sales-sheet-preview'}">
					Preview
				</router-link>
			</div>
		</div>

		<div class="subhead">
			<h3>Products</h3>

			<div class="input-group">
				<label>PDF Name (optional)</label>
				<input type="text" name="sheet-name" v-model="name">
			</div>
		</div>

		<div class="table-wrapper carts pdf-cart">
			<table v-if="sheet.length">
				<thead>
					<tr>
						<th>Thumbnail</th>
						<th>Product</th>
						<th>Description</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(product, ind) in sheet"
						:class="editing[ind] || product.note.text ? 'has-extra': ''">

						<td>
							<img v-if="product.image" :src="product.image">
						</td>
						<td>
							<router-link :to="{ path: '/product/' + product.id }">
								{{product.name}}
							</router-link>
						</td>
						<td>{{product.description}}</td>
						<td>
							<a v-on:click="noteAddEdit(ind, product.id)" class="btn">
								<span v-if="!product.note.text">Add</span><span v-else>Edit</span> Note
							</a>

							<a v-on:click="remove(product.id)" class="btn">Remove</a>
						</td>

						<div v-if="editing[ind]" class="row-extra">
							<input v-model="product.note.text" type="text">
							<a v-on:click="product.note.text = ''; $set(editing, ind, false);" class="btn">
								<span v-if="!product.note.text">&times;</span>
								<span v-else>Clear</span>
							</a>

							<a v-on:click="noteSave(ind, product.id)" class="btn">Save</a>
						</div>
						<div v-else-if="product.note.text" class="row-extra" >
							<p>
								{{ product.note.text }}
								<small>(Added: {{ $date(product.note.created) }})</small>
							</p>
						</div>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="cart-actions" v-if="sheet.length">
			<a class="btn" @click="clear()">Clear Cart</a>
		</div>

		<div v-else>
			<p>Products added to your PDF cart will display here.</p>
		</div>

		<div v-if="sheet.length">
			<h2>Customer Information</h2>

			<customerSelectAdd 
				:key="uuid"
				ref="customerSelectAdd">
			</customerSelectAdd>

			<div>
				<h3>Generate and Send PDF</h3>

				<div class="btn-group">
					<a v-on:click="submit()" class="btn">Submit</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import customerSelectAdd from "../components/CustomerSelectAdd.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'SheetCart',
	data: function() {
		return {
			editing: [],
			name: '',
			uuid: ''
		}
	},
	components: { customerSelectAdd },
	mounted: function() {
		var self = this;
		var user_id = 1;

		this.name = this.getSheetData().sheet_name;
		this.uuid = this.getSheetData().sheet_uuid;

		// console.log('uuid', this.uuid);
	},
	computed: Object.assign(
		mapGetters({}),
		{
			sheet() {
				return this.getSheet();
			}
		}
	),
	watch: {
		name(newName, oldName) {
			this.setSheetName(newName);
		}
	},
	methods: Object.assign(
		mapGetters({
			getSheet: 'getSheet',
			getSheetData: 'getSheetData'
		}),
		mapMutations({
			removeFromSheet: 'removeFromSheet',
			updateNote: 'updateNote',
			setSheetName: 'setSheetName',
			setSheetUuid: 'setSheetUuid',
			pushToHistory: 'pushToHistory'
		}),
		mapActions({
			postSheet: 'postSheet'
		}),
		{
			reset: function(order) {
				// console.log('clearing...');
				this.pushToHistory(order);
				this.clear();

				var new_uuid = window.$uuid();

				this.name = '';

				var message = 'Your PDF';
				message += order.name ? ' "' + order.name + '"'  : '';
				message += ' was generated successfully.';

				// console.log('message', message);

				this.$emit('update', {type: 'sheet', message: message});

				// console.log('route', '/order/' + order.id);

				this.$router.push({path: '/order/' + order.id});

				// console.log('Setting uuid...');
				this.setSheetUuid(new_uuid);
				this.uuid = new_uuid;
			},
			clear: function() {
				var self = this;

				this.sheet.forEach(product => {
					self.removeFromSheet({id: product.id});
				});

				this.$emit('update', {type: 'sheet'});
			},
			remove: function(id) {
				var data = {
					id: id,
					name: this.sheet.filter(p => p.id == id)[0].name
				}

				this.removeFromSheet(data);

				this.$emit('update', {type: 'sheet', message: data.message});
			},
			noteAddEdit: function(ind, id) {
				var self = this;

				this.$set(self.editing, ind, true);

				if (!this.sheet[ind].note.created) {
					this.updateNote({
						id: id,
						note: {
							created: Date.now()
						}
					});
				}
			},
			noteSave: function(ind, id) {
				var self = this;

				this.$set(self.editing, ind, false);

				// this.updateNote({})
				// 
				// Right now, this component mutates the state
				// directly. Not sure if this okay, yet. 

				this.$emit('update', {type: 'sheet'});
			},
			submit: function() {
				var self = this,
					account = this.$refs.customerSelectAdd.account,
					contact = this.$refs.customerSelectAdd.contact,
					valid = this.$validate('order', {...account, ...contact});

				if (valid.length) {
					return;
				}

				var form_data = {
					saveModeAccount: this.$refs.customerSelectAdd.saveModeAccount,
					saveModeContact: this.$refs.customerSelectAdd.saveModeContact,
					account: {
						...account,
						contact: contact
					},
					type: 'order',
					name: this.name
				}

				this.postSheet(form_data)
					.then(response => {
						console.log('Sheet Resp', response);
						self.reset(response.data);
					})
					.catch(error => {
						console.log('Sheet Post Error', error);
					})
					.finally();
			}
		}
	)
}
</script>