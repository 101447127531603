<template>
	<div class="app">
		<header class="header">
			<router-link :to="{ name: 'home' }" class="logo">
				<img src="/images/logo.svg" />
			</router-link>

			<nav class="primary-nav" 
				v-if="getIsLoggedIn">

				<router-link :to="{ name: 'cart' }" :class="'nav-item' + (route == 'cart' ? ' active': '')">
					<span>Labels {{cart.length ? '(' + cart.length + ')': ''}}</span>
				</router-link>

				<router-link :to="{ name: 'about' }" :class="'nav-item' + (route == 'about' ? ' active': '')">
					<span>How to Use</span>
				</router-link>

				<router-link :to="{ name: 'hub' }" :class="'nav-item' + (route == 'hub' ? ' active': '')">
					<span>My Hub</span>
				</router-link>

				<a class="nav-item" @click="logoutUser"><span>Logout</span></a>
			</nav>

			<router-link :to="{ name: 'sheet' }" 
				:class="'top-nav btn' + (route == 'product-sheet' ? ' active': '')" 
				v-if="getIsLoggedIn">
				
				<span>Product Sheet PDF {{sheet.length ? '(' + sheet.length + ')': ''}}</span>
			</router-link>

			<div class="header-bottom">
				<h1><span>Cutting accessories</span></h1>
			</div>
		</header>

		<div class="app-body">
			<div id="loading-lock" class="loading-lock">
				<img src="/images/loading.gif" />
			</div>

			<router-view
				v-if="config.isMounted"
				@update="handleUpdate"
				@modal="handleModal"
				@notify="handleNotification"
				@duplicate="handleDuplicate">
			</router-view>
		</div>

		<footer class="footer">
			<p>For Internal Use Only</p>
		</footer>

		<modal ref="modalContainer">
		</modal>

		<notification ref="notificationContainer">
		</notification>
	</div>		
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Modal from "../components/Modal.vue";
import Notification from "../components/Notification.vue";

// Hi There! This component contains a config object in data. 
// Here's what it does. (and, more importantly, why it doesn't
// follow Vue's usual pattern)
// 
// (boolean) isMounted 
// 
// forces the Vue lifecycle for any child components (that is 
// to say, just about all of them) to wait for this component
// to complete the $root.recall() of data out of storage. It's
// so those persistent data are born reactive (esp. on a 
// refresh). It seems odd to me, but components mount upward
// in Vue, children first. 
// 
// 
// (object) storage 
// 
// is a config array for the global properties 
// namespace. It doesn't need to be here, as it can be safely
// referenced directly in mounted(), but this draws attention
// to it as requiring some manual namespace configuration for
// any data structures that must persist (and be reactive) 
// throughout the entire application. ($root's data in app.js).
// 
// Other data can (and probably should) be handled asynchronously 
// by the affected components (but probably only the route 
// endpoints in /pages/ rather than the reusable, abstract 
// components in /components/). My thinking here is that if 
// another user adds a customer (or product, or something else 
// that is generally useful to everyone) the app should get it
// on-the-fly. User-specific content (e.g. "my cart") shouldn't
// (especially if we end up dealing with mobile OS storage 
// that has to wait for an internet connection)
// 

export default {
	components: {Modal, Notification},
	data: function() {
		return {
			config: {
				isMounted: false,
				storage: ['Cart', 'Sheet']
			}
		}
	},
	computed: Object.assign(
		mapGetters({
			getIsLoggedIn: 'getIsLoggedIn'
			// cart: 'getCart',
			// sheet: 'getSheet'
		}),
		{
			cart: {
				get() {
					return this.getCart();
				},
				set(cart) {
					return cart;
				}
			},
			sheet: {
				get() {
					return this.getSheet();
				},
				set(sheet) {
					return sheet;
				}
			},
			route: function() {
				return this.$route.params.name;
			}
		}
	),
	mounted: function() {
		var self = this;

		for (var prop of this.config.storage) {
			this.$store.dispatch('fetch', {
				cart: prop.toLowerCase(), 
				model: prop
			});
		}

		// console.log('mounted', this.$route);

		if (this.$route.name == 'login') {
			this.config.isMounted = true;
		} else {
			this.fetchCategories()
				.then(response => {
					self.loadCategories(response.data.data);
					this.config.isMounted = true;
				});
		}
	},
	methods: Object.assign(
		mapGetters({
			getCart: 'getCart',
			getCartData: 'getCartData',
			getSheet: 'getSheet',
			getSheetData: 'getSheetData'
		}),
		mapMutations({
			setUser: 'setUser',
			setCart: 'setCart',
			addToCart: 'addToCart',
			setCartName: 'setCartName',
			setSheet: 'setSheet',
			addToSheet: 'addToSheet',
			setSheetName: 'setSheetName',
			loadCategories: 'loadCategories'
		}),
		mapActions({
			postSheet: 'postSheet',
			postCart: 'postCart',
			// isLoggedIn: 'isLoggedIn',
			fetchCategories: 'fetchCategories',
			logout: 'logout'
		}),
		{
			handleNotification: function(message) {
				this.$refs.notificationContainer.openNotification(message);
			},
			handleUpdate: function(construct) {
				var self = this;

				var prop = construct.type,
					message = construct.message;

				var getter = 'get' + construct.type.substring(0,1).toUpperCase() + construct.type.substring(1) + 'Data';

				var data = {
					cart: prop,
					name: self[getter]().name,
					uuid: self[getter]().uuid,
					products: this[prop],
				}

				if (!prop) {
					console.log('Something went wrong.');
					return;
				}

				// Legacy Local Storage feature. will not work with 
				// the updated data object format.
				// 
				this.$store.dispatch('save', data);

				// switch (prop) {
				// 	case 'sheet':
				// 		this.postSheet(data);
				// 		break;
				// 	case 'cart':
				// 		this.postCart(data);
				// 		break;
				// 	default:
				// 		console.log('How did we get here?');
				// 		break;
				// }

				// Right now, this spits out a success message without respect to 
				// the post response from the server.
				// 
				this.handleNotification(message);
			},
			handleModal: function(arg) {
				if (arg.command == 'open') {
					this.$refs.modalContainer.openModal(arg.data);
				}

				if (arg.command == 'close') {
					this.$refs.modalContainer.closeModal();
				}
			},
			handleDuplicate: function(data, overwrite) {
				var self = this;
				var kind = data.order.cart_type == 'label' ? 'cart' : 'sheet';
				var confirmed = !!overwrite;

				// console.log('Duplication!', data.products, confirmed, this[kind].length);

				if (!this[kind].length) {
					confirmed = true;
				}

				if (!confirmed) {
					this.handleModal({
						command: 'open',
						data: {
							header: '<h2>Items in your cart will be overwritten</h2>',
							body: '<p>Do you want to continue?</p>',
							footer: 'confirm-duplicate',
							orderObject: data
						}
					});

					return;
				}

				if (kind == 'cart') {
					this.setCart([]);
					this.setCartName('');
				}

				if (kind == 'sheet') {
					this.setSheet([]);
					this.setSheetName('');
				}

				data.products.forEach(function(product) {
					if (kind == 'cart') {
						var data = {
							quantity: 1,
							product: product
						};

						self.addToCart(data);

						self.handleUpdate({
							type: 'cart',
							message: data.message
						})
					}

					if (kind == 'sheet') {
						var data = product;
						data.note = {};
						self.addToSheet(data);
						self.handleUpdate({
							type: 'sheet',
							message: data.message
						})
					}
				});
			},
			logoutUser() {
				var self = this;

				this.logout()
					.then(response => {
						window.axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrf_token;
						self.$router.push({name: 'login'});
					})
					.catch(error => {
						console.log('Logout Error', error);
					});
			},
		}
	)
};
</script>



