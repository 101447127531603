<template>
	<div class="breadcrumb">
		<div v-for="(link, ind) in breadcrumbs"
			:key="link.name + '-' + ind"
			class="breadcrumb-item">
			<router-link 
				v-if="ind + 1 != breadcrumbs.length"
				:to="{path: link.path}">

				{{link.name}}
			</router-link>

			<span v-else>
				{{link.name}}
			</span>
		</div>
	</div>
</template>

<script>

export default {
	name: 'breadcrumb',
	props: ['breadcrumbs'],
	data: function() {
		return {

		}
	},
	mounted: function() {
		var self = this;
	}
}
</script>