import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from './pages/Home.vue';
import Login from './pages/Login.vue';
import About from './pages/About.vue';
import Hub from './pages/Hub.vue';
import OrderDetail from './pages/OrderDetail.vue';
import LabelCart from './pages/LabelCart.vue';
import SheetCart from './pages/SheetCart.vue';
// import Accounts from './pages/Accounts.vue';
import AccountAddEdit from './pages/AccountAddEdit.vue';

import products from "./pages/Products.vue";
import productDetails from "./pages/Product.vue";

import SheetPreview from "./pages/SheetPreview.vue";

// import Categories from './components/Categories.vue';
// import Category from './components/Category.vue';
// import Product from './components/Product.vue';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	linkExactActiveClass: 'active',
	routes: [
		// Main Nav
		{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/about',
			name: 'about',
			component: About
		},
		{
			path: '/hub',
			name: 'hub',
			component: Hub
		},
		{
			path: '/cart',
			name: 'cart',
			component: LabelCart
		},
		// {
		// 	path: '/accounts',
		// 	name: 'accounts',
		// 	component: Accounts
		// },
		{
			path: '/product-sheet',
			name: 'sheet',
			component: SheetCart
		},

		// Interior Pages
		{
			path: '/categories/:category_name',
			name: 'products-by-category-name',
			component: products
		},
		{
			path: '/products',
			name: 'all-products',
			component: products
		},
		{
			path: '/search/:query',
			name: 'search-results',
			component: products
		},
		{
			path: '/search',
			name: 'search-page',
			component: products
		},
		{
			path: '/product/:id',
			name: 'product-details',
			component: productDetails
		},
		{
			path: '/order/:id',
			name: 'order-details',
			component: OrderDetail
		},
		{
			path: '/edit/account/',
			name: 'add-account',
			component: AccountAddEdit
		},
		{
			path: '/edit/account/:id',
			name: 'edit-account',
			component: AccountAddEdit
		},
		{
			path: '/sales-sheet-preview/',
			name: 'sales-sheet-preview',
			component: SheetPreview
		},
		{
			path: '/sales-sheet/:id',
			name: 'sales-sheet',
			component: SheetPreview
		},
		{
			path: '/login',
			name: 'login',
			component: Login
		},
		{
			path: '/logout',
			name: 'logout',
			component: Login
		}
	]
});

export default router;