var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.name == "All"
    ? _c(
        "router-link",
        { staticClass: "category-link", attrs: { to: { path: "/products" } } },
        [
          _c("figure", [_c("img", { attrs: { src: _vm.image } })]),
          _vm._v(" "),
          _c("h2", { staticClass: "category-title" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.name) + "\n\t"),
          ]),
        ]
      )
    : _c(
        "router-link",
        {
          staticClass: "category-link",
          attrs: { to: { path: "/categories/" + _vm.name } },
        },
        [
          _c("figure", [_c("img", { attrs: { src: _vm.image } })]),
          _vm._v(" "),
          _c("h2", { staticClass: "category-title" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.name) + "\n\t"),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }