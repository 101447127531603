var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "product-" + _vm.product.id } }, [
    _c(
      "div",
      { class: _vm.image ? "product-info" : "product-info full" },
      [
        !_vm.product ? _c("h1", [_vm._v("Something went wrong")]) : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "product-header",
            attrs: { "data-id": _vm.product.id },
          },
          [
            _c("h2", [
              _c("span", { attrs: { "data-search": "field" } }, [
                _vm._v(_vm._s(_vm.product.name)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "product-actions" }, [
              _c(
                "a",
                {
                  staticClass: "btn",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.sheetAdd()
                    },
                  },
                },
                [_vm._v("Add to PDF")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "inp-btn" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.qty,
                      expression: "qty",
                    },
                  ],
                  staticClass: "btn",
                  attrs: {
                    type: "number",
                    min: "1",
                    name: "product-" + _vm.product.id,
                  },
                  domProps: { value: _vm.qty },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.qty = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn with-number",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.cartAdd()
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tLabels\n\t\t\t\t\t")]
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.product.description
          ? _c("p", [
              _c("strong", [_vm._v("Notes:")]),
              _vm._v(" "),
              _c("span", { attrs: { "data-search": "field" } }, [
                _vm._v(_vm._s(_vm.product.description)),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.attributes && Object.keys(_vm.attributes).length
          ? _c("table", { staticClass: "stripe" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.attributes, function (val, attr) {
                  return val
                    ? _c("tr", [
                        _c("td", { attrs: { "data-attr": attr } }, [
                          _vm._v(_vm._s(_vm.$niceAttr(attr))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", { attrs: { "data-search": "field" } }, [
                            _vm._v(_vm._s(val)),
                          ]),
                        ]),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ])
          : _c("div", [_c("p", [_vm._v("No Attributes found.")])]),
        _vm._v(" "),
        _vm.alternates && _vm.alternates.length
          ? _c("strong", [_vm._v("\n\t\t\tAlternative Stryker Parts\n\t\t")])
          : _vm._e(),
        _vm._v(" "),
        _vm.alternates && _vm.alternates.length
          ? _c("table", { staticClass: "stripe" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.alternates, function (part) {
                  return _c("tr", [
                    _c("td", [
                      _c("span", { attrs: { "data-search": "field" } }, [
                        _vm._v(_vm._s(part.relationship)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { path: "/product/" + part.id } } },
                          [
                            _c("span", { attrs: { "data-search": "field" } }, [
                              _vm._v(_vm._s(part.name)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.competitors && _vm.competitors.length
          ? _c("strong", [_vm._v("\n\t\t\tCompetitor Replacements\n\t\t")])
          : _vm._e(),
        _vm._v(" "),
        _vm.competitors && _vm.competitors.length
          ? _c("table", { staticClass: "stripe" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.competitors, function (part) {
                  return _c("tr", [
                    _c("td", [
                      _c("span", { attrs: { "data-search": "field" } }, [
                        _vm._v(_vm._s(part.brand)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { attrs: { "data-search": "field" } }, [
                        _vm._v(_vm._s(part.type)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { attrs: { "data-search": "field" } }, [
                        _vm._v(_vm._s(part.part_no)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { attrs: { "data-search": "field" } }, [
                        _vm._v(_vm._s(part.name)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { attrs: { "data-search": "field" } }, [
                        _vm._v(_vm._s(part.desc)),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.name != "product-details"
          ? _c(
              "router-link",
              {
                staticClass: "btn",
                attrs: { to: { path: "/product/" + _vm.product.id } },
              },
              [_vm._v("\n\t\t\tDetails\n\t\t")]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.image
      ? _c("div", { staticClass: "product-images" }, [
          _vm.image
            ? _c("figure", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary product-image-zoom",
                    on: {
                      click: function ($event) {
                        return _vm.modal({
                          command: "open",
                          data: {
                            header: "<h2>" + _vm.product.name + "</h2>",
                            body:
                              "<figure><img src='" +
                              _vm.image +
                              "' /></figure>",
                            footer: false,
                          },
                        })
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          version: "1.1",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          x: "0px",
                          y: "0px",
                          viewBox: "0 0 50 50",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M35.96,32.03l-4.02-1.14c2.92-3.18,4.71-7.41,4.71-12.06C36.65,9,28.66,1,18.83,1S1,9,1,18.83s8,17.83,17.83,17.83 c4.64,0,8.88-1.79,12.06-4.71l1.14,4.02L45.06,49l1.51-1.51l0.92-0.92L49,45.06L35.96,32.03z M18.83,31.08 c-6.76,0-12.26-5.5-12.26-12.26s5.5-12.26,12.26-12.26s12.26,5.5,12.26,12.26S25.58,31.08,18.83,31.08z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("img", { attrs: { src: _vm.image } }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.thumbs.length && _vm.$route.name == "product-details"
            ? _c(
                "figure",
                { staticClass: "product-image-thumbs" },
                _vm._l(_vm.thumbs, function (img) {
                  return _c("img", { attrs: { src: img.thumb } })
                }),
                0
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Value")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Relationship")]),
        _vm._v(" "),
        _c("th", [_vm._v("Part Number")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Brand")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Part Number")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Description")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }