<template>
	<div :class="wrapperClass">
		<h2 v-if="header">{{ header }}</h2>
		<h2 v-else>All Products</h2>

		<div class="product-results"> <!-- v-if="header != 'Search Products'" -->
			<h2>{{ products.length ? products.length : "No " }} Result<span v-if="products.length != 1">s</span></h2>
			<!-- <small>
				Paginated: page {{ pagination.current }}. showing {{ products.length }} of {{pagination.total}} Results.
			</small> -->
		</div>

		<search v-if="isMounted" 
			@update-search="updateSearch"
			:term="$route.params.query"
			:autocompleteDisabled="false"
			:catName="header" 
			:catId="catId">
		</search>

		<bulk-add v-if="products"
			@update="handleUpdate"
			@select="handleSelect"
			:products="products"
			:selection="selection">
		</bulk-add>

		<filters v-if="Object.keys(filters_meta).length"
			:filters_meta="filters_meta"
			@select="handleSelect">
		</filters>

		<div class="product-row" v-for="product in products" :key="product.id">
			<div class="bulk-add-selector">
				<input type="checkbox"
					@change="updateSelected($event, product.id)"
					:name="'product-' + product.id"
					:product-id="product.id" />
			</div>

			<product class="product"
				@update="handleUpdate"
				@modal="handleModal"
				:highlight="highlight"
				:product="product"
				:catId="catId">
			</product>
		</div>

		<!-- <pagination 
			@page="page"
			:here="pagination.current"
			:last="pagination.last_page">
		</pagination> -->

	</div>
</template>

<script>
import product from "../components/Product.vue";
import bulkAdd from "../components/BulkAdd.vue";
import search from "../components/Search.vue";
import pagination from "../components/Pagination.vue";
import filters from "../components/Filters.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'products',
	components: {
		product,
		bulkAdd,
		search,
		pagination,
		filters
	},
	data: function() {
		return {
			header: '',
			catId: 0,
			wrapperClass: 'category',
			isMounted: false,
			// products: [],
			selection: [],
			highlight: ''
		}
	},
	computed: {
		products: function() {
			var self = this;

			if (this.$route.name == 'products-by-category-name') {
				// return this.getProductsByCategoryId()(self.catId);
			}

			if (this.$route.name == 'all-products') {
				// this.catId = 0;
				// this.products = this.getProducts();
				// var db_products = this.fetchProducts();
				// this.products = await this.fetchProducts();
			}
			return this.getProducts();
		},
		pagination: function() {
			return this.getPagination();
		},
		filters_meta: function() {
			return this.getFiltersMeta();
		}
	},
	mounted: function() {
		var self = this;

		this.resetFilters();
		this.setFiltersMeta({});
		this.loadProducts([]);

		if (this.$route.name == 'products-by-category-name') {
			this.header = this.$route.params.category_name + ' Category';
			this.catId = this.getCategoryByName()(self.$route.params.category_name);
			// this.resetFilters();
			this.mergeFilters({'category': this.catId});
		}

		if (this.$route.name.includes('search-') ) {
			this.updateSearch(this.$route.params.query);
		}

		if (this.$route.name == 'all-products') {
			// this.catId = 0;
			// this.products = this.getProducts();
			// var db_products = this.fetchProducts();
			// this.resetFilters();
		}

		this.fetchProducts(1);

		this.isMounted = true;
	},
	methods: Object.assign(
		mapActions({
			fetchProducts: 'fetchProducts',
			mergeFilters: 'mergeFilters',
			resetFilters: 'resetFilters',
		}),
		mapMutations({
			loadProducts: 'loadProducts',
			setFiltersMeta: 'setFiltersMeta'
		}),
		mapGetters({
			getCategoryByName: 'getCategoryByName',
			getProductsByCategoryId: 'getProductsByCategoryId',
			getProducts: 'getProducts',
			searchKeyword: 'searchKeyword',
			getPagination: 'getPagination',
			getFilters: 'getFilters',
			getFiltersMeta: 'getFiltersMeta'
		}),
		{
			updateSelected: function(e, id) {
				if (e.target.checked) {
					this.selection.push(id);
				} else {
					this.selection = this.selection.filter(prid => prid != id);
				}
			},
			page: function(target) {
				this.fetchProducts(target);
			},
			handleSelect: function(str) {
				var self = this;

				var cbox_nodes = document.querySelectorAll('[product-id]');
				if (str == 'all') {
					cbox_nodes.forEach(function(cbox) {
						if (!cbox.checked) {
							self.selection.push(Number(cbox.getAttribute('product-id')));
						}
						cbox.checked = true;
					});
				}

				if (str == 'none') {
					cbox_nodes.forEach(function(cbox) {
						cbox.checked = false;
					});
					self.selection = [];
				}

				if (str == 'invert') {
					cbox_nodes.forEach(function(cbox) {
						cbox.checked = !cbox.checked;
						if (cbox.checked) {
							self.selection.push(Number(cbox.getAttribute('product-id')));
						} else {
							self.selection = self.selection.filter(id => id != cbox.getAttribute('product-id'));
						}
					});
				}
			},
			updateSearch: function(inp) {
				// this.products = inp ? this.searchKeyword()(inp) : [];
				console.log('Update:', inp);

				this.mergeFilters({'search': inp});
				this.fetchProducts();

				this.highlight = inp;
				this.header = inp ? 
					'Search Results for "' + inp + '"' :
					'Search Products';
			},
			handleUpdate: function(obj) {
				this.$emit('update', obj);
			},
			handleModal: function(arg) {
				this.$emit('modal', arg);
			},
		},
	)
}
</script>