var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "filter-group-container" + (_vm.active ? " active" : ""),
      attrs: { id: _vm.id },
    },
    [
      _c("label", { attrs: { "data-attr": _vm.id } }, [
        _vm._v("\n\t\t" + _vm._s(_vm.$niceAttr(_vm.id)) + "\n\t\t"),
        _vm.products_range
          ? _c("span", [_vm._v("(" + _vm._s(_vm.products_range) + ")")])
          : _vm.filter.products
          ? _c("span", [_vm._v("(" + _vm._s(_vm.filter.products) + ")")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "filter-range" }, [
        _c("div", { staticClass: "range" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val.min,
                expression: "val.min",
              },
            ],
            staticClass: "lower-bound",
            attrs: {
              type: "number",
              step: _vm.incr,
              min: _vm.filter.min,
              max: _vm.filter.max,
              placeholder: _vm.filter.min,
            },
            domProps: { value: _vm.val.min },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.val, "min", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val.max,
                expression: "val.max",
              },
            ],
            staticClass: "upper-bound",
            attrs: {
              type: "number",
              step: _vm.incr,
              min: _vm.filter.min,
              max: _vm.filter.max,
              placeholder: _vm.filter.max,
            },
            domProps: { value: _vm.val.max },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.val, "max", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("input", {
            staticClass: "submit-range btn btn-primary",
            attrs: { type: "submit", value: "Filter" },
            on: {
              click: function ($event) {
                return _vm.updateFilter()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "labels-container" }, [
          _c("label", { staticClass: "label-min" }, [
            _vm._v(_vm._s(_vm.filter.min.toFixed(2))),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "label-max" }, [
            _vm._v(_vm._s(_vm.filter.max.toFixed(2))),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }