<template>
	<div class="login-page">
		<div class="login-box">
			<h2>Stryker Cutting</h2>

			<!-- <div>User: {{user.name}}</div> -->

			<div class="form" >
				<div class="input-group">
					<label>Email address</label>
					<input type="text" v-model="email" v-on:keyup.enter="login">
				</div>

				<div class="input-group">
					<label>Password</label>
					<input type="password" v-model="password" v-on:keyup.enter="login">
				</div>

				<div class="btn-group">
					<button class="btn btn-primary" @click="login">
						Log In
					</button>

					<a target="_blank" href="https://passwordreset.microsoftonline.com/?ru=https%3a%2f%2flogin.microsoftonline.com" class="btn btn-secondary">
						Reset Password
					</a>
				</div>

				<div class="error" v-if="error">
					{{error}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'login',
	data: function() {
		return {
			email: '',
			password: '',
			error: ''
		}
	},
	computed: Object.assign(
		mapGetters({
			user: 'getUser'
		}),
		{}
	),
	watch: {
		user(response) {
			// I know it isn't literally a "response" to Vue,
			// just go with it.
			if (response.id) {
				this.$router.push({name: 'home'});
			}

			if (response.error) {
				this.error = response.error;
			}
		}
	},
	mounted: function() {
		var self = this;

		if (this.user.id) {
			this.$router.push({name: 'home'});
		}
	},
	methods: Object.assign(
		mapActions({
			loginUser: 'loginUser',
			getUserId: 'getUserId',
			getUserData: 'getUserData',
			fetchCategories: 'fetchCategories'
		}),
		mapMutations({
			setUser: 'setUser',
			loadCategories: 'loadCategories'
		}),
		mapGetters({
			getUser: 'getUser'
		}),
		{
			login: function() {
				var self = this;
				// console.log('Logging in...');

				var auth = {
					email: this.email,
					password: this.password
				}

				this.loginUser(auth)
					.then(response => {
                        window.axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrf_token;

						self.fetchCategories()
							.then(response => {
								self.loadCategories(response.data.data);
							})

						self.setUser({id: 'pending'});

						// console.log('Get user?', self.getUser());

						self.getUserId().then(function(resp) {
							self.setUser(resp.data);

							self.getUserData();
						})

						self.$router.push({name: 'home'});
					})
					.catch(error => {
						console.log('User Login Error', error.response.data);
                        this.error = error.response.data.message
					})
					.finally();
			},

		}
	)

}
</script>
