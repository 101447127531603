var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tab-nav" }, [
      _c(
        "button",
        {
          class: _vm.tab == "hub" ? "tab active" : "tab",
          on: {
            click: function ($event) {
              return _vm.toggle("hub")
            },
          },
        },
        [_vm._v("\n\t\t\tMy Hub\n\t\t")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          class: _vm.tab == "accounts" ? "tab active" : "tab",
          on: {
            click: function ($event) {
              return _vm.toggle("accounts")
            },
          },
        },
        [_vm._v("\n\t\t\tMy Accounts\n\t\t")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          class: _vm.tab == "orders" ? "tab active" : "tab",
          on: {
            click: function ($event) {
              return _vm.toggle("orders")
            },
          },
        },
        [_vm._v("\n\t\t\tMy Label Orders\n\t\t")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          class: _vm.tab == "pdfs" ? "tab active" : "tab",
          on: {
            click: function ($event) {
              return _vm.toggle("pdfs")
            },
          },
        },
        [_vm._v("\n\t\t\tMy Sales Sheets\n\t\t")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          class: _vm.tab == "full-print" ? "tab active" : "tab",
          staticStyle: { display: "none" },
          on: {
            click: function ($event) {
              return _vm.toggle("full-print")
            },
          },
        },
        [_vm._v("\n\t\t\tOrder Full Print Product Sheet\n\t\t")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tab-body" }, [
      _vm.tab == "hub"
        ? _c("div", { staticClass: "tab-content", attrs: { id: "hub" } }, [
            _c("header", { staticClass: "subhead" }, [
              _c("h2", [_vm._v("My Account")]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.logoutUser()
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tLogout\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _vm.user.roles
              ? _c("p", [
                  _vm._v("\n\t\t\t\tUser Type: "),
                  _c("span", [_vm._v(_vm._s(_vm.user.roles[0].name))]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n\t\t\t\tFirst Name: "),
              _c("span", [_vm._v(_vm._s(_vm.user.first_name))]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n\t\t\t\tLast Name: "),
              _c("span", [_vm._v(_vm._s(_vm.user.last_name))]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n\t\t\t\tEmail: "),
              _c("span", [_vm._v(_vm._s(_vm.user.email))]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n\t\t\t\tPhone: "),
              _c("span", [_vm._v(_vm._s(_vm.user.phone))]),
            ]),
            _vm._v(" "),
            _vm.user.accounts
              ? _c("p", [
                  _vm._v("\n\t\t\t\tActive Accounts: "),
                  _c("span", [_vm._v(_vm._s(_vm.user.accounts.length))]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == "accounts"
        ? _c("div", { staticClass: "tab-content", attrs: { id: "accounts" } }, [
            _c("header", { staticClass: "subhead" }, [
              _c("h2", [_vm._v("\n\t\t\t\t\tAccounts\n\t\t\t\t")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn",
                      attrs: { to: { name: "add-account" } },
                    },
                    [_vm._v("\n\t\t\t\t\t\tAdd New\n\t\t\t\t\t")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.accounts.length
              ? _c(
                  "div",
                  { staticClass: "accounts-grid" },
                  _vm._l(_vm.accounts, function (acc) {
                    return _c(
                      "div",
                      { staticClass: "account" },
                      [
                        _c("accountDetail", {
                          key: acc.id,
                          staticClass: "account-card",
                          attrs: {
                            "data-id": acc.id,
                            noclass: true,
                            account: acc,
                            order: false,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", [_c("p", [_vm._v("No Accounts found.")])]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == "orders"
        ? _c("div", { staticClass: "tab-content", attrs: { id: "orders" } }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "table-wrapper" }, [
              _c("table", [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.labelHistory, function (order) {
                    return _vm.labelHistory.length
                      ? _c("orderRow", {
                          key: order.id,
                          attrs: { type: "label", order: order },
                          on: { duplicate: _vm.handleDuplicate },
                        })
                      : _vm._e()
                  }),
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == "pdfs"
        ? _c("div", { staticClass: "tab-content", attrs: { id: "pdfs" } }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "table-wrapper" }, [
              _c("table", [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.pdfHistory, function (order) {
                    return _vm.pdfHistory.length
                      ? _c("orderRow", {
                          key: order.id,
                          attrs: { type: "product", order: order },
                          on: { duplicate: _vm.handleDuplicate },
                        })
                      : _vm._e()
                  }),
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == "full-print"
        ? _c(
            "div",
            { staticClass: "tab-content", attrs: { id: "full-print" } },
            [
              _c("h2", [
                _vm._v("\n\t\t\t\tOrder Full Print Product Sheet\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\tPlace an order for the entire Stryker Cutting catalog.\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("customerSelectAdd", { ref: "customerSelectAdd" }),
              _vm._v(" "),
              _c("div", [
                _c("h3", [_vm._v("Alternative Stryker Parts")]),
                _vm._v(" "),
                _c("div", {}, [
                  _c("p", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.alternates_include,
                          expression: "alternates_include",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "include",
                        name: "include",
                      },
                      domProps: {
                        value: _vm.alternates_include ? "checked" : "",
                        checked: Array.isArray(_vm.alternates_include)
                          ? _vm._i(
                              _vm.alternates_include,
                              _vm.alternates_include ? "checked" : ""
                            ) > -1
                          : _vm.alternates_include,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.alternates_include,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = _vm.alternates_include ? "checked" : "",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.alternates_include = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.alternates_include = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.alternates_include = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "include" } }, [
                      _vm._v(
                        "Include alternate parts information in print catalog"
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("h3", [_vm._v("Submit Order")]),
                _vm._v(" "),
                _c("div", { staticClass: "btn-group" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.full_print_order()
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subhead" }, [
      _c("h2", [_vm._v("Label Order History")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Order Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Customer")]),
        _vm._v(" "),
        _c("th", { staticClass: "min-175" }, [_vm._v("Order Items")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subhead" }, [
      _c("h2", [_vm._v("Product Sheet Order History")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Order Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Customer")]),
        _vm._v(" "),
        _c("th", [_vm._v("Order Items")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }