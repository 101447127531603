var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sales-sheet-page" }, [
    _c(
      "div",
      { staticClass: "subhead" },
      [
        _c(
          "router-link",
          { staticClass: "btn", attrs: { to: { name: "sheet" } } },
          [_vm._v("\n\t\t\t\n\t\t\t« Product Sheet PDF\n\t\t")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sales-sheet preview" }, [
      _c("div", { staticClass: "pdf-wrapper" }, [
        _c("div", { staticClass: "sales-sheet-header" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "pdf-header" }, [
            _c("h2", [
              _vm._v(
                "\n\t\t\t\t\t\tOrthopaedic Instruments product sheet\n\t\t\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n\t\t\t\t\t\tCreated: "),
              _c("span", [_vm._v(_vm._s(_vm.created))]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "sales-sheet-body" },
          _vm._l(_vm.sheet, function (product, ind) {
            return _c("li", [
              _c(
                "div",
                { staticClass: "product-info" },
                [
                  _c("p", [
                    _c("strong", [_vm._v("Part number:")]),
                    _vm._v(" " + _vm._s(product.name) + "\n\t\t\t\t\t\t"),
                  ]),
                  _vm._v(" "),
                  _vm._l(product.attributes, function (val, attr) {
                    return _c("p", [
                      _c("strong", [_vm._v(_vm._s(_vm.$niceAttr(attr)) + ":")]),
                      _vm._v(" " + _vm._s(val) + "\n\t\t\t\t\t\t"),
                    ])
                  }),
                  _vm._v(" "),
                  product.note.text
                    ? _c("p", [
                        _c("strong", [_vm._v("Additional information:")]),
                        _vm._v(
                          " " + _vm._s(product.note.text) + "\n\t\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  product.description
                    ? _c("p", [
                        _c("strong", [_vm._v("Note:")]),
                        _vm._v(
                          " " + _vm._s(product.description) + "\n\t\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "product-image" }, [
                product.image
                  ? _c("img", { attrs: { src: product.image } })
                  : _vm._e(),
              ]),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("footer", { staticClass: "sales-sheet-footer" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.user.name))]),
            _vm._v(" "),
            _c("li", [_vm._v(_vm._s(_vm.user.phone))]),
            _vm._v(" "),
            _c("li", [_vm._v(_vm._s(_vm.user.email))]),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("\n\t\t\t\t\tPg: 1 of 1\n\t\t\t\t")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pdf-logo" }, [
      _c("img", { attrs: { src: "/images/logo.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Your Stryker OI rep:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }