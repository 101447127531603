<template>
	<div class="pagination">
		<a class="btn" @click="page('first')">First</a>

		<a v-if="(here - 3) > 0" class="btn" @click="page(here - 3)">{{here - 3}}</a>
		<a v-if="(here - 2) > 0" class="btn" @click="page(here - 2)">{{here - 2}}</a>
		<a v-if="(here - 1) > 0" class="btn" @click="page(here - 1)">{{here - 1}}</a>

		<span class="btn btn-secondary">{{here}}</span>

		<a v-if="(here + 1) < last" class="btn" @click="page(here + 1)">{{here + 1}}</a>
		<a v-if="(here + 2) < last" class="btn" @click="page(here + 2)">{{here + 2}}</a>
		<a v-if="(here + 3) < last" class="btn" @click="page(here + 3)">{{here + 3}}</a>

		<a class="btn" @click="page('last')">Last</a>
	</div>
</template>

<script>

export default {
	name: 'pagination',
	props: ['here', 'last'],
	data: function() {
		return {

		}
	},
	mounted: function() {
		var self = this;
	},
	methods: {
		page: function(arg) {
			this.$emit('page', arg);
		}
	}
}
</script>