var Category = {
	state: {
		categories: []
		// {
		// 	id: 0,
		// 	name: 'All'
		// }, 
		
		// {
		// 	id: 8,
		// 	name: 'Attachment',
		// 	// image: 'https://via.placeholder.com/200x100'
		// }, {
		// 	id: 1,
		// 	name: 'Heavy Duty'
		// }, {
		// 	id: 5,
		// 	name: 'Heavy Duty CCA Blade'
		// }, {
		// 	id: 3,
		// 	name: 'Micro'
		// }, {
		// 	id: 6,
		// 	name: 'Micro Blade CCA'
		// }, {
		// 	id: 7,
		// 	name: 'Micro Bur CCA'
		// }, {
		// 	id: 2,
		// 	name: 'Specialty'
		// }]
	},
	mutations: {
		loadCategories(state, payload) {
			state.categories = payload;
		}
	},
	getters: {
		getCategories(state) {
			return state.categories;
		},
		getCategoryById: (state) => (id) => {
			if (id && state.categories.length) {
				return state.categories.filter(cat => cat.id == id)[0];
			} 
			// else {
			// 	return state.categories;
			// }
		},
		getCategoryByName: (state) => (name) => {
			if (state.categories.length) {
				return state.categories.filter(cat => cat.name == name)[0].id;
			}
		},
		getCategoryIdByName: (state) => (name) => {
			for (var cat in state.categories) {
				if (state.categories[cat].name == name) {
					return state.categories[cat].id;
				}
			}

			return false;
		}
	},
	actions: {
		fetchCategories(context, data) {
			return axios.get('/api/product-groups');
				// .then(response => {
				// 	console.log('cats resp', response);
				// })
		}
	}
}

export default Category;
