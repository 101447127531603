var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.account && _vm.contacts
    ? _c("div", { staticClass: "contacts" }, [
        _vm.contacts.length
          ? _c("header", { staticClass: "subhead" }, [
              _c("h3", [
                _vm._v(
                  "\n\t\t\tContacts from " + _vm._s(_vm.account.name) + "\n\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c("label", [_vm._v("Search")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  attrs: { type: "text", name: "search" },
                  domProps: { value: _vm.search },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value
                    },
                  },
                }),
              ]),
            ])
          : _c("p", [
              _vm._v(
                "\n\t\tNo contacts found from " +
                  _vm._s(_vm.account.name) +
                  "\n\t"
              ),
            ]),
        _vm._v(" "),
        _vm.search && _vm.filteredContacts.length
          ? _c(
              "div",
              { staticClass: "contacts-grid" },
              _vm._l(_vm.filteredContacts, function (contact) {
                return _c(
                  "div",
                  {
                    staticClass: "contact contact-card order-card",
                    attrs: { "data-selected": contact.selected },
                    on: {
                      click: function ($event) {
                        return _vm.handleSelect(contact)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "contact-details" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(contact.first_name) +
                            " " +
                            _vm._s(contact.last_name)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(contact.title))]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(contact.email))]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm.search && !_vm.filteredContacts.length
          ? _c("div", { staticClass: "accounts-grid" }, [
              _c("div", { staticClass: "contact" }, [
                _vm._v(
                  "\n\t\t\tNo results in " +
                    _vm._s(_vm.contacts.length) +
                    " contact"
                ),
                _vm.contacts.length != 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
                _vm._v(".\n\t\t"),
              ]),
            ])
          : _vm.showAll
          ? _c(
              "div",
              { staticClass: "contacts-grid" },
              [
                _vm._l(_vm.contacts, function (contact) {
                  return _c(
                    "div",
                    {
                      staticClass: "contact contact-card order-card",
                      attrs: { "data-selected": contact.selected },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelect(contact)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "contact-details" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(contact.first_name) +
                              " " +
                              _vm._s(contact.last_name)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(contact.title))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(contact.email))]),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "account" }, [
                  _vm._v(
                    "\n\t\t\tShowing " +
                      _vm._s(_vm.contacts.length - 5) +
                      " additional contact"
                  ),
                  _vm.contacts.length - 5 != 1
                    ? _c("span", [_vm._v("s")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn-grp" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn",
                        on: {
                          click: function ($event) {
                            _vm.showAll = !_vm.showAll
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\tHide\n\t\t\t\t")]
                    ),
                  ]),
                ]),
              ],
              2
            )
          : _c(
              "div",
              { staticClass: "contacts-grid" },
              [
                _vm._l(_vm.contacts.slice(0, 5), function (contact) {
                  return _c(
                    "div",
                    {
                      staticClass: "contact contact-card order-card",
                      attrs: { "data-selected": contact.selected },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelect(contact)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "contact-details" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(contact.first_name) +
                              " " +
                              _vm._s(contact.last_name)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(contact.title))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(contact.email))]),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.contacts.length - 5 > 0
                  ? _c("div", { staticClass: "account" }, [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.contacts.length - 5) +
                          " more account"
                      ),
                      _vm.contacts.length - 5 != 1
                        ? _c("span", [_vm._v("s")])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "btn-grp" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn",
                            on: {
                              click: function ($event) {
                                _vm.showAll = !_vm.showAll
                              },
                            },
                          },
                          [_vm._v("\n\t\t\t\t\tShow All\n\t\t\t\t")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }