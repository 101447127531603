<template>
	<div :class="noclass ? '' : 'account'">
		<div class="account-details">
			<p>
				{{account.name}}
			</p>

			<address class="address" v-html="$formatAddress(account)"></address>
		</div>

		<div class="account-actions"
			 v-if="account">
			<router-link :to="{path: '/edit/account/' + account.id}"
				class="btn">
				Edit
			</router-link>
		</div>

		<div class="account-no-contact"
			v-if="!contacts.length">
			<p>No contacts found.</p>
		</div>

		<div class="account-contact"
			v-for="contact in contacts">
			<p>{{contact.first_name}} {{contact.last_name}}</p>
			<p>{{contact.email}}</p>
			<p>{{contact.title}}</p>
		</div>

		<div class="account-footer"></div>

	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'accountDetail',
	props: ['account', 'noclass'],
	data: function() {
		return {
		}
	},
	mounted: function() {
		if (!this.allContacts.length) {
			this.fetchContacts([this.account.id]);
		}
	},
	computed: Object.assign(
		mapGetters({
			allContacts: 'getContacts',
		}),
		{
			contacts: function() {
				return this.allContacts.filter(contact => contact.account_id == this.account.id);
			}
		}
	),
	methods: Object.assign(
		mapActions({
			fetchContacts: 'fetchContacts'
		}),
		{}
	)
}
</script>
