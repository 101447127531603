<template>
	<tr class="orders" v-if="order">
		<td class="top">
			<span v-if="order.updated_at">
				{{$date(order.updated_at)}}
			</span>
		</td>
		<td class="top">{{order.name}}</td>
		<td class="top">
			<div>
				<address class="address" v-html="$formatAddress(order)"></address>

				<div class="account-contact" v-if="order">
					<p>{{order.first_name}} {{order.last_name}}</p>
					<p>{{order.email}}</p>
					<p>{{order.title}}</p>
				</div>
			</div>
		</td>
		<td class="top flex">
			<ul class="order-items">
				<li v-if="type == 'label'">
					<strong>QTY</strong> Part Number
				</li>

				<li v-for="item in order.order_products.slice(0, 5)">
					<p>
						<strong>{{item.qty}}</strong>
						<router-link :to="{path: '/product/' + item.id}">
							{{item.name}}
						</router-link>
					</p>
					<p>
						<small v-if="item.note">Note: {{item.note}}</small>
					</p>
				</li>
				<li v-if="order.order_products.length > 5">
					{{order.order_products.length - 5}} more item<span v-if="(order.order_products.length - 5) != 1">s</span>.
				</li>
			</ul>
		</td>
		<td class="top">
			<p v-if="order.updated_at">Submitted: {{$date(order.updated_at)}}</p>
			<p v-else>Pending</p>
		</td>
		<td class="top btn-grp">
			<router-link :to="{path: '/order/' + order.id}" class="btn">Details</router-link>
			<a :href="'orders/' + type +  'sheet/pdf/' + order.id" target="_blank" class="btn">PDF</a>
			<button @click="duplicate(order)" class="btn">Duplicate</button>
		</td>
	</tr>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import AccountDetails from "../components/Account.vue";

export default {
	name: 'orderRow',
	props: ['order', 'type', 'product_ids'],
	components: { AccountDetails },
	data: function() {
		return {

		}
	},
	computed: Object.assign(
		mapGetters({
			products: 'getProducts'
		}),
		{
			
		}
	),
	mounted: function() {
		var self = this;

		var ids = [];

		// console.log('order', this.order.order_products.slice(0, 5));

		this.order.order_products.forEach(function(product) {
			ids.push(product.product_id);
		});

		this.fetchProductById(ids);

	},
	methods: Object.assign(
		mapActions({
			fetchProductById: 'fetchProductById'
		}),
		{
			duplicate: function(order) {
				var self = this;

				this.$emit('duplicate', {
					order: order, 
					products: order.order_products.map(item => item.product)
				});
			}
		}
	)
}
</script>