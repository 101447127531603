<template>
	<router-link v-if="name == 'All'"
		:to="{path: '/products'}"
		class="category-link">
		<figure>
			<img :src="image">
		</figure>

		<h2 class="category-title">
			{{name}}
		</h2>
	</router-link>

	<router-link v-else
		:to="{path: '/categories/' + name}"
		class="category-link">
		<figure>
			<img :src="image">
		</figure>

		<h2 class="category-title">
			{{name}}
		</h2>
	</router-link>
</template>

<script>

export default {
	name: 'category',
	props: ['category'],
	data: function() {
		return {
			id: 0,
			name: '',
			image: ''
		}
	},
	mounted: function() {
		var self = this;

		this.id = this.category.id;
		this.name = this.category.name;
		this.image = this.category.image || '/images/cat-' + this.category.id + '.png';
	}

}
</script>