var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order
    ? _c("tr", { staticClass: "orders" }, [
        _c("td", { staticClass: "top" }, [
          _vm.order.updated_at
            ? _c("span", [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.$date(_vm.order.updated_at)) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "top" }, [_vm._v(_vm._s(_vm.order.name))]),
        _vm._v(" "),
        _c("td", { staticClass: "top" }, [
          _c("div", [
            _c("address", {
              staticClass: "address",
              domProps: { innerHTML: _vm._s(_vm.$formatAddress(_vm.order)) },
            }),
            _vm._v(" "),
            _vm.order
              ? _c("div", { staticClass: "account-contact" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.order.first_name) +
                        " " +
                        _vm._s(_vm.order.last_name)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.order.email))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.order.title))]),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "top flex" }, [
          _c(
            "ul",
            { staticClass: "order-items" },
            [
              _vm.type == "label"
                ? _c("li", [
                    _c("strong", [_vm._v("QTY")]),
                    _vm._v(" Part Number\n\t\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.order.order_products.slice(0, 5), function (item) {
                return _c("li", [
                  _c(
                    "p",
                    [
                      _c("strong", [_vm._v(_vm._s(item.qty))]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/product/" + item.id } } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(item.name) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    item.note
                      ? _c("small", [_vm._v("Note: " + _vm._s(item.note))])
                      : _vm._e(),
                  ]),
                ])
              }),
              _vm._v(" "),
              _vm.order.order_products.length > 5
                ? _c("li", [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.order.order_products.length - 5) +
                        " more item"
                    ),
                    _vm.order.order_products.length - 5 != 1
                      ? _c("span", [_vm._v("s")])
                      : _vm._e(),
                    _vm._v(".\n\t\t\t"),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "top" }, [
          _vm.order.updated_at
            ? _c("p", [
                _vm._v("Submitted: " + _vm._s(_vm.$date(_vm.order.updated_at))),
              ])
            : _c("p", [_vm._v("Pending")]),
        ]),
        _vm._v(" "),
        _c(
          "td",
          { staticClass: "top btn-grp" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn",
                attrs: { to: { path: "/order/" + _vm.order.id } },
              },
              [_vm._v("Details")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn",
                attrs: {
                  href: "orders/" + _vm.type + "sheet/pdf/" + _vm.order.id,
                  target: "_blank",
                },
              },
              [_vm._v("PDF")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.duplicate(_vm.order)
                  },
                },
              },
              [_vm._v("Duplicate")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }