var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page" }, [
    _c("div", { staticClass: "login-box" }, [
      _c("h2", [_vm._v("Stryker Cutting")]),
      _vm._v(" "),
      _c("div", { staticClass: "form" }, [
        _c("div", { staticClass: "input-group" }, [
          _c("label", [_vm._v("Email address")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.email },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.login.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("label", [_vm._v("Password")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            attrs: { type: "password" },
            domProps: { value: _vm.password },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.login.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", on: { click: _vm.login } },
            [_vm._v("\n\t\t\t\t\tLog In\n\t\t\t\t")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-secondary",
              attrs: {
                target: "_blank",
                href: "https://passwordreset.microsoftonline.com/?ru=https%3a%2f%2flogin.microsoftonline.com",
              },
            },
            [_vm._v("\n\t\t\t\t\tReset Password\n\t\t\t\t")]
          ),
        ]),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "error" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.error) + "\n\t\t\t"),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }